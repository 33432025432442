import React, { useState, useEffect } from "react";
import { TextField, InputAdornment } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate, useLocation } from "react-router-dom";
import { API_BASE_URL } from "./constants";
import "./Login.css";

const PasswordStep = ({ setToken }) => {
  const [password, setPasswordState] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [hasPassword, setHasPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const email = location.state?.email || "";

  useEffect(() => {
    const checkUserPassword = async () => {
      const response = await fetch(`${API_BASE_URL}/check_password`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        const result = await response.json();
        setHasPassword(result.hasPassword);
      }
    };

    checkUserPassword();
  }, [email]);

  const checkUserOrders = async (token) => {
    try {
      const orderResponse = await fetch(`${API_BASE_URL}/orders`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      const orderResult = await orderResponse.json();

      // If the user has placed orders, redirect them to the /orders page
      if (orderResponse.ok && orderResult.orders.length > 0) {
        navigate("/orders");
        return true;
      }
    } catch (error) {
      console.error("Error checking user orders", error);
    }
    return false;
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setError("");
    setMessage("");
    try {
      const response = await fetch(`${API_BASE_URL}/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });

      const result = await response.json();

      if (response.ok) {
        setToken(result.token);
        setMessage("Login successful!");

        // Check if the user has already placed orders
        const hasOrders = await checkUserOrders(result.token);

        if (!hasOrders) {
          navigate("/work-location"); // Navigate to the /location page if no orders exist
        }
      } else {
        setError(result.error);
      }
    } catch (error) {
      setError("An error occurred during login.");
    }
  };

  const handleCreatePassword = async (e) => {
    e.preventDefault();
    setError("");
    setMessage("");
    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}/set_password`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });

      const result = await response.json();

      if (response.ok) {
        setMessage("Password created successfully.");
        setToken(result.token);

        // Check if the user has already placed orders
        const hasOrders = await checkUserOrders(result.token);

        if (!hasOrders) {
          navigate("/login/email"); // Redirect to the next login step
        }
      } else {
        setError(result.error);
      }
    } catch (error) {
      setError("An error occurred while creating the password.");
    }
  };

  return (
    <div className="login-page">
      <div className="login-container">
        <div className="login-form">
          {/* <h2>{hasPassword ? "Enter Your Password" : "Create Your Password"}</h2> */}
          <h2>{"Enter Your Password"}</h2>
          {error && <p className="error-message">{error}</p>}
          {message && <p className="success-message">{message}</p>}
          {/* <form onSubmit={hasPassword ? handleLogin : handleCreatePassword}> */}
          <form onSubmit={handleLogin}>
            <TextField
              label="Email"
              value={email}
              variant="outlined"
              fullWidth
              margin="normal"
              InputProps={{
                readOnly: true,
              }}
              sx={{
                marginTop: "6px",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": { borderColor: "black" },
                  "&:hover fieldset": { borderColor: "black" },
                  "&.Mui-focused fieldset": { borderColor: "black" },
                  color: "black",
                },
                "& .MuiInputLabel-root": { color: "black" },
                "& .MuiInputLabel-root.Mui-focused": { color: "black" },
              }}
            />
            <label className="password-field">Password</label>
            <TextField
              placeholder={
                hasPassword ? "Enter your password" : "Create your password"
              }
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => setPasswordState(e.target.value)}
              variant="outlined"
              fullWidth
              margin="normal"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <span
                      onClick={() => setShowPassword(!showPassword)}
                      style={{ cursor: "pointer" }}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </span>
                  </InputAdornment>
                ),
              }}
              sx={{
                marginTop: "6px",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": { borderColor: "black" },
                  "&:hover fieldset": { borderColor: "black" },
                  "&.Mui-focused fieldset": { borderColor: "black" },
                  color: "black",
                },
                "& .MuiInputLabel-root": { color: "black" },
                "& .MuiInputLabel-root.Mui-focused": { color: "black" },
              }}
            />
            {/* {!hasPassword && (
                            <TextField
                                placeholder="Confirm your password"
                                type={showConfirmPassword ? "text" : "password"}
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <span onClick={() => setShowConfirmPassword(!showConfirmPassword)} style={{ cursor: 'pointer' }}>
                                                {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                            </span>
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{
                                    marginTop: '6px',
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': { borderColor: 'black' },
                                        '&:hover fieldset': { borderColor: 'black' },
                                        '&.Mui-focused fieldset': { borderColor: 'black' },
                                        color: 'black',
                                    },
                                    '& .MuiInputLabel-root': { color: 'black' },
                                    '& .MuiInputLabel-root.Mui-focused': { color: 'black' },
                                }}
                            />
                        )} */}
            <div style={{ display: "flex", justifyContent: "center" }}>
              <button type="submit" className="password-submit-button">
                {/* {hasPassword ? "Login" : "Create Password"} <ArrowForwardIcon /> */}
                {"Login"} <ArrowForwardIcon />
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PasswordStep;
