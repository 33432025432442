import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Navbar from './Navbar'; 
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
} from '@mui/material'; 
import './Profile.css'; 
import { API_BASE_URL } from './constants';

const Profile = () => {
    const [user, setUser] = useState(null); 
    const [loading, setLoading] = useState(true); 
    const [error, setError] = useState(null);
    const [openModal, setOpenModal] = useState(false); 
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [successMessage, setSuccessMessage] = useState(''); 

    useEffect(() => {
        const fetchUserProfile = async () => {
            const token = localStorage.getItem('token'); 
            try {
                const response = await fetch(`${API_BASE_URL}/profile`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch user profile');
                }

                const data = await response.json();
                setUser(data); 
            } catch (err) {
                setError(err.message); 
            } finally {
                setLoading(false); 
            }
        };

        fetchUserProfile();
    }, []); 

    const handleChangePassword = () => {
        setOpenModal(true); 
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setCurrentPassword('');
        setNewPassword('');
        setConfirmPassword('');
        setPasswordError('');
        setSuccessMessage(''); 
    };

    const handleConfirmChangePassword = async () => {
        setPasswordError(''); 
        setSuccessMessage(''); 
        
        if (newPassword !== confirmPassword) {
            setPasswordError("New passwords don't match");
            return;
        }

        const token = localStorage.getItem('token');
        try {
            const response = await fetch(`${API_BASE_URL}/change-password`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    current_password: currentPassword,
                    new_password: newPassword
                }),
            });

            if (response.ok) {
                setSuccessMessage("Password changed successfully!"); 
                handleCloseModal();
            } else {
                const errorData = await response.json();
                setPasswordError(errorData.error || 'Failed to change password.');
            }
        } catch (error) {
            setPasswordError("An error occurred while changing the password.");
        }
    };

    return (
        <div className="profile-page">
            <Navbar cartItemCount={0} userBalance={0} />
            <div className="profile-container">
                <h2 className="profile-title">My Profile</h2>
                {loading ? (
                    <p className="loading-text">Loading...</p>
                ) : error ? (
                    <p className="error">{error}</p>
                ) : user ? (
                    <div className="profile-info">
                        <p><strong>Username:</strong> {user.username}</p>
                        <p><strong>Email:</strong> {user.email}</p>
                        <p><strong>Points:</strong> {user.points}</p>
                        <p><strong>Home Address:</strong> {user.home_address || 'N/A'}</p>
                        <p><strong>Work Address:</strong> {user.work_address || 'N/A'}</p>
                        {successMessage && <p className="success-message">{successMessage}</p>} 
                        <div className="profile-actions">
                            <Button variant="outlined" onClick={handleChangePassword} className="profile-action-button">
                                Change Password
                            </Button>
                            {/* <span className="separator">|</span> 
                            <Link to="/location" className="profile-action-link">
                                Change Address
                            </Link> */}
                        </div>
                    </div>
                ) : (
                    <p>No user information found.</p>
                )}
            </div>

            <Dialog open={openModal} onClose={handleCloseModal}>
                <DialogTitle>Change Password</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Current Password"
                        type="password"
                        fullWidth
                        margin="dense"
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                    />
                    <TextField
                        label="New Password"
                        type="password"
                        fullWidth
                        margin="dense"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                    />
                    <TextField
                        label="Confirm New Password"
                        type="password"
                        fullWidth
                        margin="dense"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    {passwordError && <p className="error">{passwordError}</p>} 
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal} color="secondary">Cancel</Button>
                    <Button onClick={handleConfirmChangePassword} color="primary">Confirm</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default Profile;
