// import React, { useState, useEffect } from "react";
// import { Link, useLocation } from "react-router-dom";
// import "./Navbar.css";
// import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
// import AccountCircle from "@mui/icons-material/AccountCircle";
// import MenuIcon from "@mui/icons-material/Menu";
// import CloseIcon from "@mui/icons-material/Close";
// import HomeIcon from "@mui/icons-material/Home";
// import PersonIcon from "@mui/icons-material/Person";
// import ListAltIcon from "@mui/icons-material/ListAlt";
// import SearchIcon from "@mui/icons-material/Search";
// import google from "../assets/images/header logo.png";
// import { API_BASE_URL } from "./constants";

// const Navbar = ({ cartItemCount, userBalance }) => {
//   const [sidenavVisible, setSidenavVisible] = useState(false);
//   const [hasPurchased, setHasPurchased] = useState(false);
//   const location = useLocation();

//   const toggleSidenav = () => {
//     setSidenavVisible(!sidenavVisible);
//   };

//   const handleLogout = () => {
//     localStorage.removeItem("token");
//     window.location.href = "/login/email";
//   };

//   useEffect(() => {
//     const checkPurchaseStatus = async () => {
//       try {
//         const token = localStorage.getItem("token");
//         const response = await fetch(`${API_BASE_URL}/orders`, {
//           method: "GET",
//           headers: {
//             Authorization: `Bearer ${token}`,
//             "Content-Type": "application/json",
//           },
//         });

//         if (response.ok) {
//           const data = await response.json();
//           if (data.orders.length > 0) {
//             setHasPurchased(true);
//           }
//         }
//       } catch (error) {
//         console.error("Error checking purchase status:", error);
//       }
//     };

//     checkPurchaseStatus();
//   }, []);

//   const isOrdersPage =
//     location.pathname === "/orders" ||
//     location.pathname === "/profile" ||
//     location.pathname === "/cart" ||
//     location.pathname.startsWith("/products") ||
//     location.pathname.startsWith("/bundles");
//   const isProductDetailsPage = location.pathname.startsWith("/products/");

//   return (
//     <>
//       <nav className="navbar">
//         <div className="navbar-title-menu">
//           {!isOrdersPage && (
//             <button className="navbar-menu-button" onClick={toggleSidenav}>
//               <MenuIcon className="navbar-icon" />
//             </button>
//           )}
//           <img src={google} alt="logo" className="google-logo" />
//           {/* <div style={{ textAlign: "start" }}>
//             <h1 className="navbar-title">ROCK PAPER SCISSORS</h1>
//             <h1 className="navbar-title">GIFTING STORE</h1>
//           </div> */}
//         </div>
//         <div className="navbar-info">
//           {!isProductDetailsPage && (
//             <>
//               <div className="navbar-user-info">
//                 {/* <AccountCircle className="navbar-icon" /> */}
//                 {/* <span className="navbar-user-balance">
//                   Balance: {userBalance} points
//                 </span> */}
//               </div>
//               <div className="navbar-cart-icon">
//                 <Link to="/cart" state={{ balance: userBalance }}>
//                   <ShoppingCartIcon className="navbar-icon" fontSize="large" />
//                   {cartItemCount > 0 && (
//                     <span className="navbar-cart-count">{cartItemCount}</span>
//                   )}
//                 </Link>
//               </div>
//             </>
//           )}
//         </div>
//       </nav>

//       {isOrdersPage ? (
//         <div className="sidenav sticky-sidenav">
//           <div className="sidenav-links">
//             {!hasPurchased && (
//               <Link to="/dashboard" className="sidenav-link">
//                 <HomeIcon className="sidenav-icon" /> Home
//               </Link>
//             )}
//             <Link to="/orders" className="sidenav-link">
//               <ListAltIcon className="sidenav-icon" /> My Orders
//             </Link>
//             <Link to="/profile" className="sidenav-link">
//               <PersonIcon className="sidenav-icon" /> Profile
//             </Link>
//           </div>
//           <button className="sidenav-logout" onClick={handleLogout}>
//             Logout
//           </button>
//         </div>
//       ) : (
//         <>
//           <div className={`sidenav ${sidenavVisible ? "sidenav-visible" : ""}`}>
//             <button className="close-sidenav-button" onClick={toggleSidenav}>
//               <CloseIcon className="navbar-icon" />
//             </button>
//             {!hasPurchased && (
//               <Link
//                 to="/dashboard"
//                 className="sidenav-link"
//                 onClick={toggleSidenav}
//               >
//                 <HomeIcon className="sidenav-icon" /> Home
//               </Link>
//             )}
//             <Link to="/orders" className="sidenav-link" onClick={toggleSidenav}>
//               <ListAltIcon className="sidenav-icon" /> My Orders
//             </Link>
//             <Link
//               to="/profile"
//               className="sidenav-link"
//               onClick={toggleSidenav}
//             >
//               <PersonIcon className="sidenav-icon" /> Profile
//             </Link>
//             <button className="sidenav-logout" onClick={handleLogout}>
//               Logout
//             </button>
//           </div>
//           {sidenavVisible && (
//             <div className="sidenav-overlay" onClick={toggleSidenav}></div>
//           )}
//         </>
//       )}
//     </>
//   );
// };

// export default Navbar;
import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "./Navbar.css";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import HomeIcon from "@mui/icons-material/Home";
import PersonIcon from "@mui/icons-material/Person";
import ListAltIcon from "@mui/icons-material/ListAlt";
import SearchIcon from "@mui/icons-material/Search";
import google from "../assets/images/header logo.png";
import { API_BASE_URL } from "./constants";

const Navbar = ({ cartItemCount, userBalance }) => {
  const [sidenavVisible, setSidenavVisible] = useState(false);
  const [hasPurchased, setHasPurchased] = useState(false);
  const location = useLocation();

  const toggleSidenav = () => {
    setSidenavVisible(!sidenavVisible);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    window.location.href = "/login/email";
  };

  useEffect(() => {
    const checkPurchaseStatus = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await fetch(`${API_BASE_URL}/orders`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        if (response.ok) {
          const data = await response.json();
          if (data.orders.length > 0) {
            setHasPurchased(true);
          }
        }
      } catch (error) {
        console.error("Error checking purchase status:", error);
      }
    };

    checkPurchaseStatus();
  }, []);

  const isProductDetailsPage = location.pathname.startsWith("/products/");

  return (
    <>
      <nav className="navbar">
        <div className="navbar-title-menu">
          <button className="navbar-menu-button" onClick={toggleSidenav}>
            <MenuIcon className="navbar-icon" />
          </button>
          <img src={google} alt="logo" className="google-logo" />
        </div>
        <div className="navbar-info">
          {!isProductDetailsPage && (
            <>
              <div className="navbar-cart-icon">
                <Link to="/cart" state={{ balance: userBalance }}>
                  <ShoppingCartIcon className="navbar-icon" fontSize="large" />
                  {cartItemCount > 0 && (
                    <span className="navbar-cart-count">{cartItemCount}</span>
                  )}
                </Link>
              </div>
            </>
          )}
        </div>
      </nav>

      <div className={`sidenav ${sidenavVisible ? "sidenav-visible" : ""}`}>
        <button className="close-sidenav-button" onClick={toggleSidenav}>
          <CloseIcon className="navbar-icon" />
        </button>
        {!hasPurchased && (
          <Link to="/dashboard" className="sidenav-link" onClick={toggleSidenav}>
            <HomeIcon className="sidenav-icon" /> Home
          </Link>
        )}
        <Link to="/orders" className="sidenav-link" onClick={toggleSidenav}>
          <ListAltIcon className="sidenav-icon" /> My Orders
        </Link>
        <Link to="/profile" className="sidenav-link" onClick={toggleSidenav}>
          <PersonIcon className="sidenav-icon" /> Profile
        </Link>
        <button className="sidenav-logout" onClick={handleLogout}>
          Logout
        </button>
      </div>
      {sidenavVisible && (
        <div className="sidenav-overlay" onClick={toggleSidenav}></div>
      )}
    </>
  );
};

export default Navbar;
