import axios from 'axios';
import { API_BASE_URL } from '../components/constants';

// Set the JWT token in the headers for authenticated requests
export const setAuthToken = (token) => {
    if (token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
        delete axios.defaults.headers.common['Authorization'];
    }
};

// Login API call
export const login = async (email, password) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/login`, { email, password });
        return response.data;
    } catch (error) {
        return { error: error.response.data.error };
    }
};

// Generate Password API call
export const generatePassword = async (email) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/generate_password`, { email });
        return response.data;
    } catch (error) {
        return { error: error.response.data.error };
    }
};

// Set Password API call
export const setPassword = async (email, password) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/set_password`, { email, password });
        return response.data;
    } catch (error) {
        return { error: error.response.data.error };
    }
};

// Set Address API call
export const setAddress = async (addressData) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/set_address`, addressData);
        return response.data;
    } catch (error) {
        return { error: error.response.data.error };
    }
};

// Get available products API call
export const getProducts = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/products`);
        return response.data;
    } catch (error) {
        return { error: error.response.data.error };
    }
};

// Buy product API call
export const buyProduct = async (productId, userId) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/buy/${productId}`, { user_id: userId });
        return response.data;
    } catch (error) {
        return { error: error.response.data.error };
    }
};
