import React, { useState, useEffect } from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate } from "react-router-dom";
import "./AdminCycle.css";
import { API_BASE_URL } from "./constants";

const AdminCycle = () => {
  const [cycles, setCycles] = useState([]);
  const [newCycleName, setNewCycleName] = useState("");
  const [newCycleState, setNewCycleState] = useState("active");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const fetchCycles = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await fetch(`${API_BASE_URL}/cycles`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) throw new Error("Failed to fetch cycles");
      const data = await response.json();
      setCycles(data);
    } catch (error) {
      console.error(error);
      setErrorMessage("Session expired. Try logging in again");
    }
  };

  useEffect(() => {
    fetchCycles();
  }, []);

  const handleAddCycle = async (e) => {
    e.preventDefault();
    if (!newCycleName || !newCycleState) {
      setErrorMessage("Both fields are required.");
      return;
    }
    setErrorMessage("");

    try {
      const token = localStorage.getItem("token");
      const response = await fetch(`${API_BASE_URL}/cycles`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ name: newCycleName, state: newCycleState }),
      });

      if (!response.ok) throw new Error("Failed to add cycle");

      await response.json();
      setNewCycleName("");
      setNewCycleState("active");
      fetchCycles();
    } catch (error) {
      console.error(error);
      setErrorMessage("Session expired. Try loggin in again");
    }
  };

  const handleCycleClick = (cycleId) => {
    navigate(`/admin/cycles/${cycleId}/products`);
  };

  return (
    <div className="admin-dashboard">
      <div className="admin-container glass-effect">
        <div className="admin-form">
          <h2>Admin Dashboard</h2>

          <h3>Existing Stores</h3>
          <div className="cycle-grid">
            {cycles.map((cycle) => (
              <div
                key={cycle.id}
                className={`cycle-card`}
                onClick={() => handleCycleClick(cycle.id)}
              >
                <h4>{cycle.name}</h4>
                <p>Status: {cycle.state}</p>
              </div>
            ))}
          </div>

          <h3>Add New Store</h3>
          <form onSubmit={handleAddCycle}>
            <input
              type="text"
              placeholder="Store Name"
              value={newCycleName}
              onChange={(e) => setNewCycleName(e.target.value)}
              required
              className="cycle-input"
            />
            {errorMessage && <p className="error-message">{errorMessage}</p>}

            <div className="button-container">
              <button type="submit" className="submit-button">
                Add Store <ArrowForwardIcon className="icon" />
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AdminCycle;
