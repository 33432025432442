// import React, { useEffect, useState } from "react";
// import { useParams } from "react-router-dom";
// import AdminNavbar from "./AdminNavbar";
// import "./AdminOrders.css";
// import { API_BASE_URL } from "./constants";

// const AdminOrders = () => {
//   const { cycleId } = useParams();
//   const [orders, setOrders] = useState([]);
//   const [errorMessage, setErrorMessage] = useState("");
//   const [orderCount, setOrderCount] = useState(0);
//   const [userStats, setUserStats] = useState({
//     total_users: 0,
//     ordered_users_count: 0,
//     not_ordered_users_count: 0,
//   });
//   const [showModal, setShowModal] = useState(false); // For handling modal visibility
//   const [currentOrderId, setCurrentOrderId] = useState(null); // Store order ID when changing status
//   const [shippingId, setShippingId] = useState(""); // Store the entered shipping ID

//   useEffect(() => {
//     const fetchOrders = async () => {
//       const token = localStorage.getItem("token");

//       try {
//         const response = await fetch(
//           `${API_BASE_URL}/cycles/${cycleId}/orders`,
//           {
//             method: "GET",
//             headers: {
//               Authorization: `Bearer ${token}`,
//               "Content-Type": "application/json",
//             },
//           }
//         );

//         if (!response.ok) {
//           throw new Error("Failed to fetch orders.");
//         }

//         const data = await response.json();
//         setOrders(data.orders);
//         setOrderCount(data.orders.length);
//       } catch (error) {
//         setErrorMessage("Session expired. Try logging in again");
//         console.error("Error fetching orders:", error);
//       }
//     };

//     const fetchUserStats = async () => {
//       try {
//         const response = await fetch(
//           `${API_BASE_URL}/cycles/${cycleId}/user_order_stats`,
//           {
//             method: "GET",
//             headers: {
//               Authorization: `Bearer ${localStorage.getItem("token")}`,
//               "Content-Type": "application/json",
//             },
//           }
//         );

//         if (!response.ok) {
//           throw new Error("Failed to fetch user stats.");
//         }

//         const statsData = await response.json();
//         setUserStats(statsData);
//       } catch (error) {
//         console.error("Error fetching user stats:", error);
//       }
//     };

//     fetchOrders();
//     fetchUserStats();
//   }, [cycleId]);

//   // Function to handle status change
//   const handleStatusChange = async (orderId, newStatus) => {
//     if (newStatus === "Shipped") {
//       setCurrentOrderId(orderId);
//       setShowModal(true);
//     } else {
//       // If status is not "Confirmed", update it directly
//       updateOrderStatus(orderId, newStatus);
//     }
//   };

//   const exportToCSV = () => {
//     const csvRows = [];
//     const headers = [
//       "Order ID",
//       "User",
//       "Email",
//       "Product",
//       "Quantity",
//       "Options",
//       "Address",
//       "delivery date",
//       "State",
//       "Pincode",
//       "Status",
//       "bundle_name"
//     ];
//     csvRows.push(headers.join(","));

//     orders.forEach((order) => {
//       order.items.forEach((item) => {
//         // Assuming the address format is always: address, state, pincode
//         const addressParts = order.address.split(",");
//         const address = addressParts.slice(0, -2).join(",").trim(); // Extracting the main address
//         const state = addressParts.slice(-2, -1)[0].trim(); // Extracting the state
//         const pincode = addressParts.slice(-1)[0].trim(); // Extracting the pincode
//         const attributes = Object.entries(item.attributes)
//           .filter(([key]) => key.startsWith(item.product_name))
//           .map(([, value]) => value)
//           .join("- "); // Join the values into a single string
//         const row = [
//           order.id,
//           order.user_name,
//           order.user_email,
//           item.product_name,
//           item.quantity,
//           attributes || "",
//           `"${address}"`, // Wrapping the address in double quotes
//           order.delivery_details,
//           state,
//           pincode,
//           order.status,
//           item.bundle_name

//         ];
//         csvRows.push(row.join(","));
//       });
//     });

//     const csvContent = `data:text/csv;charset=utf-8,${csvRows.join("\n")}`;
//     const encodedUri = encodeURI(csvContent);
//     const link = document.createElement("a");
//     link.setAttribute("href", encodedUri);
//     link.setAttribute("download", `orders_cycle_${cycleId}.csv`);
//     document.body.appendChild(link);
//     link.click();
//     document.body.removeChild(link);
//   };

//   // Function to update order status with or without shipping ID
//   const updateOrderStatus = async (orderId, status, shippingId = null) => {
//     const token = localStorage.getItem("token");
//     // console.log(token);
//     const payload = { status };
//     if (shippingId) {
//       payload.shipping_id = shippingId; // Add shipping ID if provided
//     }

//     // console.log(orderId);
//     // console.log(payload);
//     try {
//       const response = await fetch(
//         `${API_BASE_URL}/orders/${orderId}/update_status`,
//         {
//           method: "PUT",
//           headers: {
//             Authorization: `Bearer ${token}`,
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify(payload),
//         }
//       );

//       if (response.ok) {
//         // Update the status in the local orders state
//         setOrders(
//           orders.map((order) =>
//             order.id === orderId ? { ...order, status } : order
//           )
//         );
//       } else {
//         console.error("Failed to update order status");
//       }
//     } catch (error) {
//       console.error("Error updating status:", error);
//     }
//   };

//   // Function to handle shipping ID submission
//   const handleShippingIdSubmit = () => {
//     // Call updateOrderStatus with the shipping ID and status "Confirmed"
//     updateOrderStatus(currentOrderId, "Shipped", shippingId);
//     setShowModal(false); // Hide the modal
//     setShippingId(""); // Clear the shipping ID input
//   };

//   return (
//     <div>
//       <AdminNavbar cycleId={cycleId} />

//       <div className="admin-orders-page">
//         <div className="orders-header">
//           <h2>Orders</h2>
//           <button onClick={exportToCSV} className="export-csv-button">
//             Export to CSV
//           </button>
//         </div>

//         {errorMessage && <p className="error-message">{errorMessage}</p>}

//         <p>Total Orders: {orderCount}</p>
//         <p>Total Users: {userStats.total_users}</p>
//         <p>Users Who Ordered: {userStats.ordered_users_count}</p>
//         <p>Users Who Did Not Order: {userStats.not_ordered_users_count}</p>

//         {orders.length === 0 ? (
//           <p>No orders found for this cycle.</p>
//         ) : (
//           <table className="orders-table">
//             <thead>
//               <tr>
//                 <th>Order ID</th>
//                 <th>User</th>
//                 <th>Products</th>
//                 <th>Address</th>
//                 <th>Delivery Date</th>
//                 <th>Status</th>

//               </tr>
//             </thead>
//             <tbody>
//               {orders.map((order) => (
//                 <tr key={order.id}>
//                   <td>{order.id}</td>
//                   <td>
//                     <div>
//                       <p>{order.user_name}</p>
//                       <p>{order.user_email}</p>
//                     </div>
//                   </td>
//                   <td>
//                     {order.items.map((item, index) => (
//                       <div key={index}>
//                         {item.product_name}
//                         <p>Quantity: {item.quantity}</p>
//                         {item.attributes[item.product_name] && (
//                           <span>{item.attributes[item.product_name]}</span>
//                         )}
//                         {item.attributes[item.product_name + "_2"] && (
//                           <span>
//                             , {item.attributes[item.product_name + "_2"]}
//                           </span>
//                         )}
//                       </div>
//                     ))}
//                   </td>
//                   <td>{order.address}</td>
//                   <td>{order.delivery_details}</td>
//                   <td>
//                     <select
//                       value={order.status}
//                       onChange={(e) =>
//                         handleStatusChange(order.id, e.target.value)
//                       }
//                     >
//                       <option value="Pending">Pending</option>
//                       <option value="Confirmed">Confirmed</option>
//                       <option value="Shipped">Shipped</option>
//                       <option value="delivered">Delivered</option>
//                     </select>
//                   </td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         )}

//         {/* Modal for entering shipping ID */}
//         {showModal && (
//           <div className="modal">
//             <div className="modal-content">
//               <h3>Enter Shipping ID</h3>
//               <input
//                 type="text"
//                 value={shippingId}
//                 onChange={(e) => setShippingId(e.target.value)}
//                 placeholder="Shipping ID"
//               />
//               <button onClick={handleShippingIdSubmit}>Submit</button>
//               &nbsp;
//               <button onClick={() => setShowModal(false)}>Cancel</button>
//             </div>
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default AdminOrders;
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AdminNavbar from "./AdminNavbar";
import "./AdminOrders.css";
import { API_BASE_URL } from "./constants";

const AdminOrders = () => {
  const { cycleId } = useParams();
  const [orders, setOrders] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [orderCount, setOrderCount] = useState(0);
  const [userStats, setUserStats] = useState({
    total_users: 0,
    ordered_users_count: 0,
    not_ordered_users_count: 0,
  });
  const [showModal, setShowModal] = useState(false);
  const [currentOrderId, setCurrentOrderId] = useState(null);
  const [shippingId, setShippingId] = useState("");
  const [currentPage, setCurrentPage] = useState(1); // Track the current page
  const [totalPages, setTotalPages] = useState(1); // Total pages

  const limit = 10; // Set items per page

  const [distinctBundles, setDistinctBundles] = useState({});
  const [loadingCsv, setLoadingCsv] = useState(false);

  // Fetch orders with pagination
  const fetchOrders = async (page = 1) => {
    const token = localStorage.getItem("token");

    try {
      const response = await fetch(
        `${API_BASE_URL}/cycles/${cycleId}/orders?page=${page}&limit=${limit}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch orders.");
      }

      const data = await response.json();
      setOrders(data.orders);
      setOrderCount(data.total_count);
      setTotalPages(data.total_pages);
      setCurrentPage(data.current_page);
    } catch (error) {
      setErrorMessage("Session expired. Try logging in again");
      console.error("Error fetching orders:", error);
    }
  };

  const fetchUserStats = async () => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/cycles/${cycleId}/user_order_stats`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch user stats.");
      }

      const statsData = await response.json();
      setUserStats(statsData);
    } catch (error) {
      console.error("Error fetching user stats:", error);
    }
  };

  useEffect(() => {
    fetchOrders(currentPage); // Fetch orders for the current page on load
    fetchUserStats();
  }, [cycleId, currentPage]);

  // Function to handle status change
  const handleStatusChange = async (orderId, newStatus) => {
    if (newStatus === "Shipped") {
      setCurrentOrderId(orderId);
      setShowModal(true);
    } else {
      updateOrderStatus(orderId, newStatus);
    }
  };

  const fetchAllOrders = async () => {
    const token = localStorage.getItem("token");
    let allOrders = [];
    let page = 1;
    let totalFetchedOrders;

    do {
      const response = await fetch(
        `${API_BASE_URL}/cycles/${cycleId}/orders?page=${page}&limit=${limit}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) throw new Error("Failed to fetch orders.");

      const data = await response.json();
      allOrders = allOrders.concat(data.orders);
      totalFetchedOrders = data.total_count;
      page++;
    } while (allOrders.length < totalFetchedOrders);

    const userBundles = {};

    // Iterate through all orders
    allOrders.forEach((order) => {
      const userEmail = order.user_email.toLowerCase();

      // Use a Set to track unique bundles per user
      const uniqueBundles = new Set();

      order.items.forEach((item) => {
        uniqueBundles.add(item.bundle_name);
      });

      // Store the unique bundles for this user
      uniqueBundles.forEach((bundleName) => {
        if (!userBundles[bundleName]) {
          userBundles[bundleName] = 0;
        }
        userBundles[bundleName] += 1; // Increment the count for this unique bundle
      });
    });

    // Output the results

    Object.entries(userBundles).forEach(([bundleName, count], index) => {
      console.log(`${bundleName} - ${count}`);
    });

    setDistinctBundles(userBundles);

    return allOrders;
  };

  const exportToCSV = async () => {
    setLoadingCsv(true);

    const orders = await fetchAllOrders();
    const csvRows = [];
    const headers = [
      "Order ID",
      "User",
      "Email",
      "Product",
      "Quantity",
      "Options",
      "Address",
      "Delivery Date",
      "Status",
      "Bundle Name",
    ];
    csvRows.push(headers.join(","));

    orders.forEach((order) => {
      order.items.forEach((item) => {
        const attributes = Object.entries(item.attributes)
          .filter(([key]) => key.startsWith(item.product_name))
          .map(([, value]) => value)
          .join("- ");
        const row = [
          order.id,
          order.user_name,
          order.user_email,
          item.product_name,
          item.quantity,
          attributes || "",
          `"${order.address.replaceAll("#", "")}"`,
          order.delivery_details,
          order.status,
          item.bundle_name,
        ];
        csvRows.push(row.join(","));
      });
    });

    const csvContent = `data:text/csv;charset=utf-8,${csvRows.join("\n")}`;
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `orders_cycle_${cycleId}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setLoadingCsv(false);
  };

  // const exportToCSV = () => {
  //   const csvRows = [];
  //   const headers = [
  //     "Order ID",
  //     "User",
  //     "Email",
  //     "Product",
  //     "Quantity",
  //     "Options",
  //     // "Address",
  //     "delivery date",
  //     // "State",
  //     // "Pincode",
  //     "Status",
  //     "bundle_name",
  //   ];
  //   csvRows.push(headers.join(","));

  //   orders.forEach((order) => {
  //     order.items.forEach((item) => {
  //       // Assuming the address format is always: address, state, pincode
  //       const addressParts = order.address.split(",");
  //       // const address = addressParts.slice(0, -2).join(",").trim(); // Extracting the main address
  //       // const state = addressParts.slice(-2, -1)[0].trim(); // Extracting the state
  //       // const pincode = addressParts.slice(-1)[0].trim(); // Extracting the pincode
  //       const attributes = Object.entries(item.attributes)
  //         .filter(([key]) => key.startsWith(item.product_name))
  //         .map(([, value]) => value)
  //         .join("- "); // Join the values into a single string
  //       const row = [
  //         order.id,
  //         order.user_name,
  //         order.user_email,
  //         item.product_name,
  //         item.quantity,
  //         attributes || "",
  //         `"${order.address.replaceAll("#", "")}"`, // Wrapping the address in double quotes
  //         order.delivery_details,
  //         // state,
  //         // pincode,
  //         order.status,
  //         item.bundle_name,
  //       ];
  //       csvRows.push(row.join(","));
  //     });
  //   });

  //   const csvContent = `data:text/csv;charset=utf-8,${csvRows.join("\n")}`;
  //   const encodedUri = encodeURI(csvContent);
  //   const link = document.createElement("a");
  //   link.setAttribute("href", encodedUri);
  //   link.setAttribute("download", `orders_cycle_${cycleId}.csv`);
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // };

  const updateOrderStatus = async (orderId, status, shippingId = null) => {
    const token = localStorage.getItem("token");
    const payload = { status };
    if (shippingId) payload.shipping_id = shippingId;
    payload.cycle_id = cycleId;
    try {
      const response = await fetch(
        `${API_BASE_URL}/orders/${orderId}/update_status`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      if (response.ok) {
        setOrders(
          orders.map((order) =>
            order.id === orderId ? { ...order, status } : order
          )
        );
      } else {
        console.error("Failed to update order status");
      }
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  const handleShippingIdSubmit = () => {
    updateOrderStatus(currentOrderId, "Shipped", shippingId);
    setShowModal(false);
    setShippingId("");
  };

  // Pagination handlers
  const goToNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  return (
    <div>
      <AdminNavbar cycleId={cycleId} />

      <div className="admin-orders-page">
        <div className="orders-header">
          <h2>Orders</h2>
          <button onClick={exportToCSV} className="export-csv-button">
            {loadingCsv ? "Exporting..." : "Export to CSV"}
            {loadingCsv && <span className="loading-spinner"></span>}
          </button>
        </div>

        {errorMessage && <p className="error-message">{errorMessage}</p>}

        <p>Total Orders: {orderCount}</p>
        <p>Total Users: {userStats.total_users}</p>
        <p>Users Who Ordered: {userStats.ordered_users_count}</p>
        <p>Users Who Did Not Order: {userStats.not_ordered_users_count}</p>
        <div className="distinct-bundles-section">
          <ul>
            {Object.entries(distinctBundles).map(([bundleName, count]) => (
              <li key={bundleName}>
                {bundleName}: {count}
              </li>
            ))}
          </ul>
        </div>

        {orders.length === 0 ? (
          <p>No orders found for this cycle.</p>
        ) : (
          <table className="orders-table">
            <thead>
              <tr>
                <th>Order ID</th>
                <th>User</th>
                <th>Products</th>
                <th>Address</th>
                <th>Delivery Date</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {orders.map((order) => (
                <tr key={order.id}>
                  <td>{order.id}</td>
                  <td>
                    <div>
                      <p>{order.user_name}</p>
                      <p>{order.user_email}</p>
                    </div>
                  </td>
                  <td>
                    {order.items.map((item, index) => (
                      <div key={index}>
                        {item.product_name}
                        <p>Quantity: {item.quantity}</p>
                        {item.attributes[item.product_name] && (
                          <span>{item.attributes[item.product_name]}</span>
                        )}
                        {item.attributes[item.product_name + "_2"] && (
                          <span>
                            , {item.attributes[item.product_name + "_2"]}
                          </span>
                        )}
                      </div>
                    ))}
                  </td>
                  <td>{order.address}</td>
                  <td>{order.delivery_details}</td>
                  <td>
                    <select
                      value={order.status}
                      onChange={(e) =>
                        handleStatusChange(order.id, e.target.value)
                      }
                    >
                      <option value="Pending">Pending</option>
                      <option value="Confirmed">Confirmed</option>
                      <option value="Shipped">Shipped</option>
                      <option value="Delivered">Delivered</option>
                    </select>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}

        {/* Pagination Controls */}
        <div className="pagination-controls">
          <button
            className={`pagination-button ${
              currentPage === 1 ? "disabled" : ""
            }`}
            onClick={goToPreviousPage}
            disabled={currentPage === 1}
          >
            &laquo; Previous
          </button>
          <span className="pagination-info">
            Page <strong>{currentPage}</strong> of {totalPages}
          </span>
          <button
            className={`pagination-button ${
              currentPage === totalPages ? "disabled" : ""
            }`}
            onClick={goToNextPage}
            disabled={currentPage === totalPages}
          >
            Next &raquo;
          </button>
        </div>

        {/* Modal for entering shipping ID */}
        {showModal && (
          <div className="modal">
            <div className="modal-content">
              <h3>Enter Shipping ID</h3>
              <input
                type="text"
                value={shippingId}
                onChange={(e) => setShippingId(e.target.value)}
                placeholder="Shipping ID"
              />
              <button onClick={handleShippingIdSubmit}>Submit</button>
              &nbsp;
              <button onClick={() => setShowModal(false)}>Cancel</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminOrders;
