import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Filter from "./Filter";
import Navbar from "./Navbar";
import "./Dashboard.css";
import "./Filter.css";
import { API_BASE_URL } from "./constants";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
} from "@mui/material";

const Dashboard = () => {
  const [products, setProducts] = useState([]);
  const [bundles, setBundles] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [balance, setBalance] = useState(0);
  const [cartCount, setCartCount] = useState(0);
  const [isShippingCharged, setIsShippingCharged] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(true); // Add loading state
  const navigate = useNavigate();
  const productListRef = useRef(null);
  const [cycleId, setCycleId] = useState(null);
  const [isProductsVisible, setIsProductsVisible] = useState(false);
  const [isBundlesVisible, setIsBundlesVisible] = useState(false);
  const [hoveredBundleId, setHoveredBundleId] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token");
      setLoading(true); // Set loading to true when data fetching starts

      try {
        // Fetch user cycle ID
        const cycleResponse = await fetch(`${API_BASE_URL}/user/cycle-id`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        if (!cycleResponse.ok) {
          const errorData = await cycleResponse.json();
          console.error("Cycle ID fetch error:", errorData);
          setErrorMessage("Session expired. Login back.");
          setLoading(false); // Stop loading on error
          return;
        }

        const cycleData = await cycleResponse.json();
        const fetchedCycleId = cycleData.cycle_id;
        setCycleId(fetchedCycleId);

        // Fetch visibility APIs after cycle ID is set
        await fetchVisibilityData(fetchedCycleId, token);

        // Fetch user balance
        const balanceResponse = await fetch(`${API_BASE_URL}/balance`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        if (!balanceResponse.ok) {
          const errorData = await balanceResponse.json();
          console.error("Balance fetch error:", errorData);
          setErrorMessage("Session expired. Try logging in again");
          setLoading(false); // Stop loading on error
          return;
        }

        const balanceData = await balanceResponse.json();
        let initialBalance = balanceData.balance;

        // Fetch user's cart
        const cartResponse = await fetch(`${API_BASE_URL}/cart`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        if (cartResponse.ok) {
          const cartData = await cartResponse.json();
          const cartTotal = cartData.reduce((acc, item) => acc + item.price, 0);

          if (!isShippingCharged) {
            initialBalance -= 499; // Deduct shipping if not charged
            setIsShippingCharged(true);
          }

          const finalBalance = initialBalance - cartTotal;
          setBalance(finalBalance);
          setCartCount(cartData.length);
        } else {
          const errorData = await cartResponse.json();
          console.error("Cart fetch error:", errorData);
          setErrorMessage("Failed to fetch cart.");
        }

        // Fetch products and bundles associated with the user's cycle only if visibility is true
        if (isProductsVisible) {
          const productsResponse = await fetch(
            `${API_BASE_URL}/cycles/${fetchedCycleId}/user-products`,
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          );

          if (!productsResponse.ok) {
            const errorData = await productsResponse.json();
            console.error("Products fetch error:", errorData);
            setErrorMessage("Failed to fetch products.");
            setLoading(false); // Stop loading on error
            return;
          }

          const productsData = await productsResponse.json();
          setProducts(productsData.products);
          setFilteredProducts(productsData.products);
        }

        if (isBundlesVisible) {
          const bundlesResponse = await fetch(
            `${API_BASE_URL}/cycles/${fetchedCycleId}/bundles`,
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          );

          if (bundlesResponse.ok) {
            const bundlesData = await bundlesResponse.json();
            setBundles(bundlesData.bundles);
          } else {
            const errorData = await bundlesResponse.json();
            console.error("Bundles fetch error:", errorData);
            setErrorMessage("Failed to fetch bundles.");
          }
        }

        setLoading(false); // Stop loading when data is fetched
      } catch (error) {
        console.error("Fetch error:", error);
        setErrorMessage("An unexpected error occurred.");
        setLoading(false); // Stop loading on error
      }
    };

    const fetchVisibilityData = async (fetchedCycleId, token) => {
      try {
        const productsVisibility = await fetch(
          `${API_BASE_URL}/cycles/${fetchedCycleId}/products-visibility`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (productsVisibility.ok) {
          const visibility = await productsVisibility.json();
          setIsProductsVisible(visibility.is_products_visible);
        } else {
          const errorData = await productsVisibility.json();
          console.error("Visibility fetch error:", errorData);
          setErrorMessage("Failed to fetch product visibility.");
        }

        const bundlesVisibility = await fetch(
          `${API_BASE_URL}/cycles/${fetchedCycleId}/bundle-visibility`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (bundlesVisibility.ok) {
          const bundleVisibility = await bundlesVisibility.json();
          setIsBundlesVisible(bundleVisibility.is_bundle_visible);
        } else {
          const errorData = await bundlesVisibility.json();
          console.error("Visibility fetch error:", errorData);
          setErrorMessage("Failed to fetch bundle visibility.");
        }
      } catch (error) {
        console.error("Visibility fetch error:", error);
        setErrorMessage(
          "An unexpected error occurred while fetching visibility."
        );
      }
    };

    fetchData();
  }, [isShippingCharged]);

  const addToCart = async (product) => {
    if (!isShippingCharged) {
      if (balance < 499) {
        setErrorMessage("Insufficient balance to cover shipping charges.");
        return;
      }
      setBalance(balance - 499);
      setIsShippingCharged(true);
    }

    const remainingBalanceAfterShipping =
      balance - (isShippingCharged ? 0 : 499);

    if (remainingBalanceAfterShipping - product.price < 499) {
      setErrorMessage(
        "Insufficient balance to add product and cover shipping charges."
      );
      return;
    }

    const updatedBalance = remainingBalanceAfterShipping - product.price;

    const token = localStorage.getItem("token");
    const response = await fetch(`${API_BASE_URL}/cart`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ product_id: product.id }),
    });

    if (response.ok) {
      setBalance(updatedBalance);
      setSuccessMessage("Product added to cart!");
      setCartCount(cartCount + 1);
    } else {
      const errorResponse = await response.json();
      setErrorMessage("You cannot purchase anymore");
    }
  };

  const handleBundleHover = (bundleId) => {
    setHoveredBundleId(bundleId);
  };

  const handleMouseLeave = () => {
    setHoveredBundleId(null);
  };

  const handleFilterChange = ({
    searchTerm,
    priceRange,
    isNew,
    selectedCategory,
  }) => {
    let updatedProducts = [...products];

    if (searchTerm) {
      updatedProducts = updatedProducts.filter((product) =>
        product.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    if (priceRange) {
      const [min, max] = priceRange.split("-").map(Number);
      updatedProducts = updatedProducts.filter((product) => {
        const price = product.price;
        return max ? price >= min && price <= max : price >= min;
      });
    }

    if (isNew) {
      updatedProducts = updatedProducts.filter((product) => product.isNew);
    }

    if (selectedCategory) {
      updatedProducts = updatedProducts.filter(
        (product) => product.category === selectedCategory
      );
    }

    setFilteredProducts(updatedProducts);
  };

  useEffect(() => {
    if (successMessage || errorMessage) {
      const timer = setTimeout(() => {
        setSuccessMessage("");
        setErrorMessage("");
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [successMessage, errorMessage]);

  const handleProductClick = (productId) => {
    navigate(`/products/${productId}`, { state: { cartCount, balance } });
  };

  const handleBundleClick = (bundleId) => {
    navigate(`/bundles/${bundleId}`, { state: { cartCount, balance } });
  };

  return (
    <div>
      <Navbar cartItemCount={cartCount} userBalance={balance} />

      {/* Display success or error messages */}
      <div className="dashboard-messages">
        {successMessage && (
          <div className="message success-message">{successMessage}</div>
        )}
        {errorMessage && (
          <div className="message error-message">{errorMessage}</div>
        )}
      </div>

      <div className="dashboard-container">
        {/* Show loading spinner while data is being fetched */}
        {loading ? (
          <div className="loading-spinner">
            <CircularProgress />
            {/* You can replace this with a spinner component */}
          </div>
        ) : (
          <div className="dashboard-content">
            {/* Display Bundles Section */}
            {isBundlesVisible && (
              <>
                <h2 className="bundles-heading">Swag Kits</h2>
                <div className="bundle-list">
                  {bundles.length > 0 ? (
                    bundles.map((bundle) => {
                      return (
                        <div
                          className="bundle"
                          key={bundle.id}
                          onMouseEnter={() => handleBundleHover(bundle.id)}
                          onMouseLeave={handleMouseLeave}
                        >
                          <img src={bundle.image_url} />
                          <div className="bundle-info">
                            <h3>{bundle.name}</h3>
                          </div>
                          {/* Modal for displaying products */}
                          {hoveredBundleId === bundle.id && (
                            <div className="white-modal-container">
                              <div className="modal-content">
                                <img
                                  // className="bundle-modal-img"
                                  src={bundle.image_url}
                                  alt={bundle.name}
                                />
                                <h3>{bundle.name}</h3>
                                <button
                                  onClick={() => handleBundleClick(bundle.id)}
                                >
                                  View Swag Kit
                                </button>
                                <br />
                                &nbsp;
                                <ul>
                                  {bundle.products.map((product) => (
                                    <li key={product.id}>{product.name}</li>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          )}
                        </div>
                      );
                    })
                  ) : (
                    <p>No bundles available for this cycle.</p>
                  )}
                </div>
              </>
            )}

            {/* Display Products Section */}
            {isProductsVisible && (
              <>
                <h2 className="product-heading">Products</h2>
                <div className="product-list" ref={productListRef}>
                  {filteredProducts.map((product) => (
                    <div
                      className="product"
                      key={product.id}
                      onClick={() => handleProductClick(product.id)}
                    >
                      <img src={product.image_url} alt={product.name} />
                      <div className="separator"></div>
                      <div className="product-info">
                        <h3>{product.name}</h3>
                        <p className="product-description">
                          {product.description}
                        </p>
                        {/* <p className="price">Price: {product.price} points</p> */}
                        <button onClick={() => handleProductClick(product.id)}>
                          View Product
                        </button>
                      </div>
                    </div>
                  ))}
                  {filteredProducts.length === 0 && (
                    <p>No products available for this cycle.</p>
                  )}
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
