import React, { useState } from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate } from "react-router-dom";
import "./LocationStep.css";
import { API_BASE_URL } from "./constants";

const LocationStep = () => {
  // Set 'home' as the default location
  const [selectedLocation, setSelectedLocation] = useState("home");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const handleLocationSelect = (location) => {
    setSelectedLocation(location);
    setErrorMessage("");
  };

  const handleNext = () => {
    if (selectedLocation === "home") {
      navigate("/home-location");
    } else if (selectedLocation === "work") {
      navigate("/work-location");
    } else {
      setErrorMessage("Please select a location.");
    }
  };

  return (
    <div className="location-page">
      <div className="location-container glass-effect">
        <div className="location-form">
          <h2>Select Your Delivery Location</h2>

          <div className="tile-container">
            <div
              className={`location-tile ${
                selectedLocation === "home" ? "selected" : ""
              }`}
              onClick={() => handleLocationSelect("home")}
            >
              <h3>Home Location</h3>
            </div>

            <div
              className={`location-tile ${
                selectedLocation === "work" ? "selected" : ""
              }`}
              // onClick={() => handleLocationSelect('work')}
            >
              <h3>Work Location</h3>
              <p>(Unavailable)</p>
            </div>
          </div>

          {errorMessage && <p className="error-message">{errorMessage}</p>}

          <div className="button-container">
            <button onClick={handleNext} className="submit-button">
              Next <ArrowForwardIcon className="icon" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocationStep;
