import React, { useEffect, useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import "./BundleDetails.css";
import { API_BASE_URL } from "./constants";
import CircularProgress from "@mui/material/CircularProgress";

const BundleDetails = () => {
  const { bundleId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { cartCount: initialCartCount, balance: initialBalance } =
    location.state || { cartCount: 0, balance: 0 };
  const [cartCount, setCartCount] = useState(initialCartCount);
  const [balance, setBalance] = useState(initialBalance);
  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [selectedAttributes, setSelectedAttributes] = useState({});
  const [selectedAttributes2, setSelectedAttributes2] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProductsByBundle = async () => {
      const token = localStorage.getItem("token");
      setLoading(true);

      try {
        const response = await fetch(
          `${API_BASE_URL}/bundles/${bundleId}/products`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch products for this bundle");
        }

        const data = await response.json();
        setProducts(data.products);
      } catch (error) {
        setErrorMessage(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchProductsByBundle();
  }, [bundleId]);

  // Handle attribute selection per product
  //   const handleAttributeChange = (productId, attribute) => {
  //     setSelectedAttributes((prevSelected) => ({
  //       ...prevSelected,
  //       [productId]: attribute, // Update selected attribute for the product
  //     }));
  //   };
  // Handle attribute selection per product
  const handleAttributeChange = (productId, attribute, isSecondSet = false) => {
    if (isSecondSet) {
      setSelectedAttributes2((prevSelected) => ({
        ...prevSelected,
        [productId]: attribute, // Update selected attribute for the product
      }));
    } else {
      setSelectedAttributes((prevSelected) => ({
        ...prevSelected,
        [productId]: attribute, // Update selected attribute for the product
      }));
    }
  };

  const parseAttributes = (attributesString) => {
    return attributesString ? attributesString.split(",") : [];
  };

  const addToCart = async () => {
    if (balance < 0) {
      alert("Your Cart has reached its limit.");
    } else {
      setErrorMessage("");
      setSuccessMessage("");
      const token = localStorage.getItem("token");

      // Prepare selected attributes for the request body
      const selectedAttributesForRequest = {};

      // If attributes are required for the bundle, you can validate here
      //   products.forEach((product) => {
      //       if (selectedAttributes[product.id]) {
      //           selectedAttributesForRequest[product.id] = selectedAttributes[product.id];
      //       }
      //   });
      products.forEach((product) => {
        if (selectedAttributes[product.id]) {
          selectedAttributesForRequest[product.name] =
            selectedAttributes[product.id];
        }

        if (selectedAttributes2[product.id]) {
          selectedAttributesForRequest[`${product.name}_2`] =
            selectedAttributes2[product.id];
        }
      });

      // Check if all required attributes are selected for products that have attributes
      const allAttributesSelected = products.every((product) => {
        const hasAttributes1 =
          product.attributes && product.attributes.length > 0;
        const hasAttributes2 =
          product.attributes2 && product.attributes2.length > 0;

        // If product has attributes1, ensure selectedAttributes is present
        const attributes1Selected = hasAttributes1
          ? !!selectedAttributes[product.id]
          : true;

        // If product has attributes2, ensure selectedAttributes2 is present
        const attributes2Selected = hasAttributes2
          ? !!selectedAttributes2[product.id]
          : true;

        return attributes1Selected && attributes2Selected;
      });

      // console.log(allAttributesSelected);

      // If not all required attributes are selected, prevent proceeding
      if (!allAttributesSelected) {
        alert("Please select all custom options to proceed further.");
        return; // Early exit if any required attribute is missing
      }

      try {
        const response = await fetch(`${API_BASE_URL}/cart/bundle`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            bundle_id: bundleId,
            selected_attribute: selectedAttributesForRequest,
          }),
        });

        if (!response.ok) {
          throw new Error("Failed to add bundle to cart");
        }
        // console.log(product);
        setCartCount((prevCount) => prevCount + 1); // Increment cart count
        setBalance((prevBalance) => prevBalance - product.price);

        // Success - assuming the backend handles total pricing
        alert("Bundle added to cart successfully!");
        // Navigate or update state as necessary
        navigate("/dashboard", { state: { cartCount: cartCount + 1 } }); // Increment by 1 for the entire bundle
      } catch (error) {
        setErrorMessage(error.message);
      }
    }
  };
  //   const addToCart = async () => {
  //     setErrorMessage("");
  //     setSuccessMessage("");
  //     const token = localStorage.getItem("token");

  //     // Prepare selected attributes for the request body
  //     const selectedAttributesForRequest = {};

  //     // If attributes are required for the bundle, validate here
  //     products.forEach((product) => {
  //       if (selectedAttributes[product.id]) {
  //         selectedAttributesForRequest[product.id] =
  //           selectedAttributes[product.id];
  //       }
  //     });

  //     // Check if any attributes are selected (if required)
  //     if (Object.keys(selectedAttributesForRequest).length === 0) {
  //       setErrorMessage(
  //         "Please select attributes for at least one product in the bundle."
  //       );
  //       return; // Early exit if no attributes selected
  //     }

  //     try {
  //       // Fetch the bundle cost from the backend
  //       const bundleResponse = await fetch(
  //         `${API_BASE_URL}/bundles/${bundleId}`,
  //         {
  //           method: "GET",
  //           headers: {
  //             Authorization: `Bearer ${token}`,
  //             "Content-Type": "application/json",
  //           },
  //         }
  //       );

  //       if (!bundleResponse.ok) {
  //         throw new Error("Failed to fetch bundle details");
  //       }

  //       const bundleData = await bundleResponse.json();
  //       const bundleCost = bundleData.bundle_cost;

  //       const response = await fetch(`${API_BASE_URL}/cart/bundle`, {
  //         method: "POST",
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify({
  //           bundle_id: bundleId,
  //           selected_attribute: selectedAttributesForRequest,
  //         }),
  //       });

  //       if (!response.ok) {
  //         throw new Error("Failed to add bundle to cart");
  //       }

  //       setCartCount((prevCount) => prevCount + 1); // Increment cart count for the bundle

  //       // Deduct the bundle cost from the user's balance
  //       setBalance((prevBalance) => prevBalance - bundleCost);

  //       // Success message after adding the bundle to the cart
  //       setSuccessMessage("Bundle added to cart successfully!");
  //       navigate("/dashboard", {
  //         state: { cartCount: cartCount + 1, balance: balance - bundleCost },
  //       });
  //     } catch (error) {
  //       setErrorMessage(error.message);
  //     }
  //   };

  const removeFromCart = async () => {
    setErrorMessage("");
    setSuccessMessage("");
    const token = localStorage.getItem("token");

    try {
      const response = await fetch(`${API_BASE_URL}/cart/bundle`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ bundle_id: bundleId }),
      });

      if (!response.ok) {
        throw new Error("Failed to remove bundle from cart");
      }

      const totalBundlePrice = products.reduce(
        (acc, product) => acc + product.price,
        0
      );
      setCartCount((prevCount) => prevCount - products.length);
      setBalance((prevBalance) => prevBalance + totalBundlePrice);

      setSuccessMessage("Bundle removed from cart successfully!");
      navigate("/dashboard", {
        state: {
          cartCount: cartCount - products.length,
          balance: balance + totalBundlePrice,
        },
      });
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  const handleBackClick = () => {
    navigate("/dashboard");
  };

  return (
    <div className="bundle-container">
      <Navbar cartItemCount={cartCount} userBalance={balance} />
      <div className="bundle-details-container">
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "10px",
          }}
        >
          <button
            onClick={handleBackClick}
            className="back-button"
            style={{
              backgroundColor: "blue",
              color: "white",
              padding: "10px 20px",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
            &larr;&nbsp; Back
          </button>
        </div>

        <h2>Products in Bundle</h2>
        {/* <p className="bundle-matter">
          <span style={{ color: "red" }}>[Attention!] </span>
          Double-check your Swag Kit selection before ‘checking out’ – once
          confirmed, we won’t be able to make any changes/ modifications or
          cancellations. Refer to the size chart & select the suitable Apparel
          sizes too! (where applicable)
        </p> */}
        <p>Note: All products will have Google branding.</p>
        {errorMessage && <div className="error-message">{errorMessage}</div>}
        {successMessage && (
          <div className="success-message">{successMessage}</div>
        )}

        {loading ? (
          <div className="loading-spinner">
            <CircularProgress />
          </div>
        ) : (
          <>
            {products.length > 0 ? (
              <table className="bundle-products-table">
                <thead>
                  <tr>
                    <th>Image</th>
                    <th>Name</th>
                    <th>Description</th>
                    <th>Options</th>
                    {/* Show the second options column if at least one product has attributes2 */}
                    {products.some(
                      (product) =>
                        parseAttributes(product.attributes2).length > 0
                    ) && <th>Options</th>}
                  </tr>
                </thead>
                <tbody>
                  {products.map((product) => {
                    const attributes = parseAttributes(product.attributes);
                    const attributes2 = parseAttributes(product.attributes2);

                    return (
                      <tr key={product.id}>
                        <td style={{ textAlign: "center" }}>
                          <a
                            href={product.image_url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={product.image_url}
                              alt={product.name}
                              width="150"
                            />
                            <br />
                            <p>View Image</p>
                          </a>
                        </td>

                        <td>
                          <div>
                            <p>{product.name}</p>
                            <br />
                            {product.size_url && (
                              <p>
                                <a
                                  href={product.size_url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  View Size Chart
                                </a>
                              </p>
                            )}
                          </div>
                        </td>
                        {/* <td className="description-matter">
                          {product.description}
                        </td> */}
                        <td>{product.description}</td>

                        {/* Dropdown for first set of attributes */}
                        <td className="dropdownBundle">
                          {attributes.length > 0 ? (
                            <select
                              value={selectedAttributes[product.id] || ""}
                              onChange={(e) =>
                                handleAttributeChange(
                                  product.id,
                                  e.target.value
                                )
                              }
                            >
                              <option value="">Select</option>
                              {attributes.map((attribute) => (
                                <option key={attribute} value={attribute}>
                                  {attribute}
                                </option>
                              ))}
                            </select>
                          ) : (
                            ""
                          )}
                        </td>

                        {/* Conditionally render second dropdown */}
                        {attributes2.length > 0 ||
                        products.some(
                          (p) => parseAttributes(p.attributes2).length > 0
                        ) ? (
                          <td className="dropdownBundle">
                            {attributes2.length > 0 ? (
                              <select
                                value={selectedAttributes2[product.id] || ""}
                                onChange={(e) =>
                                  handleAttributeChange(
                                    product.id,
                                    e.target.value,
                                    true
                                  )
                                }
                              >
                                <option value="">Select</option>
                                {attributes2.map((attribute) => (
                                  <option key={attribute} value={attribute}>
                                    {attribute}
                                  </option>
                                ))}
                              </select>
                            ) : (
                              ""
                            )}
                          </td>
                        ) : null}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <p>No products available in this bundle.</p>
            )}
            <div className="cart-buttons">
              <button onClick={addToCart} className="add-to-cart-button">
                Add Bundle to Cart
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default BundleDetails;
