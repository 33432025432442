import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./DeliveryLocation.css";
import deliveryImage from "../assets/images/delivery-image.jpg";
import { API_BASE_URL } from "./constants";

const DeliveryLocation = ({ token }) => {
  const [homeAddress, setHomeAddress] = useState("");
  const [workLocationDetail, setWorkLocationDetail] = useState("");
  const [deliveryOption, setDeliveryOption] = useState("");
  const [existingHomeAddress, setExistingHomeAddress] = useState("");
  const [existingWorkAddress, setExistingWorkAddress] = useState("");
  const [customWorkAddress, setCustomWorkAddress] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAddresses = async () => {
      const response = await fetch(`${API_BASE_URL}/get_addresses`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setExistingHomeAddress(data.home_address || "");
        setExistingWorkAddress(data.work_address || "");
        setHomeAddress(data.home_address || "");
        setWorkLocationDetail(data.work_address || "");
      } else {
        console.error("Failed to fetch addresses", response.statusText);
      }
    };

    fetchAddresses();
  }, [token]);

  const handleAddressSubmit = async (e) => {
    e.preventDefault();
    const addressData = {
      home_address: homeAddress,
      work_address:
        deliveryOption === "work" && workLocationDetail === "other"
          ? customWorkAddress
          : workLocationDetail,
    };

    const response = await fetch(`${API_BASE_URL}/set_address`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(addressData),
    });

    if (response.ok) {
      alert("Address saved successfully!");
      navigate("/dashboard");
    } else {
      const errorResponse = await response.json();
      alert(errorResponse.message);
    }
  };

  return (
    <div className="delivery-location-page">
      {/* Scrolling marquee text */}
      <div className="scrolling-text">
        Hey Chicha! It's time to add your address! 🏡📍
      </div>

      <div className="delivery-location-container">
        <img
          src={deliveryImage}
          alt="Delivery Illustration"
          className="delivery-image animate-slide-in"
        />

        <div className="delivery-location animate-slide-in">
          <h2 className="animate-fade-in">Choose Your Delivery Location</h2>
          <p className="animate-fade-in">
            Where should we deliver your precious packages? Make sure you select
            the right address for quick delivery!
          </p>
          <form onSubmit={handleAddressSubmit}>
            <div className="animate-fade-in">
              <input
                type="radio"
                name="location"
                value="home"
                checked={deliveryOption === "home"}
                onChange={() => {
                  setHomeAddress(existingHomeAddress);
                  setWorkLocationDetail("");
                  setCustomWorkAddress("");
                  setDeliveryOption("home");
                }}
              />
              <label>Deliver to Home</label>
              {deliveryOption === "home" && (
                <input
                  type="text"
                  placeholder="Enter your home address"
                  value={homeAddress}
                  onChange={(e) => setHomeAddress(e.target.value)}
                  className="animate-fade-in"
                />
              )}
              <div className="caption">
                Cozy home deliveries are the best! 🏡
              </div>
            </div>

            <div className="animate-fade-in">
              <input
                type="radio"
                name="location"
                value="work"
                checked={deliveryOption === "work"}
                onChange={() => {
                  setHomeAddress("");
                  setWorkLocationDetail(existingWorkAddress);
                  setCustomWorkAddress("");
                  setDeliveryOption("work");
                }}
              />
              <label>Deliver to Work</label>
              {deliveryOption === "work" && (
                <>
                  <select
                    onChange={(e) => {
                      const selectedValue = e.target.value;
                      setWorkLocationDetail(selectedValue);
                      if (selectedValue === "other") {
                        setCustomWorkAddress("");
                      }
                    }}
                    value={workLocationDetail}
                    className="animate-fade-in"
                  >
                    <option value="">Select Work Location</option>
                    <option value="hyd_office">Hyderabad Office</option>
                    <option value="bglr_office">Bangalore Office</option>
                    <option value="other">Other</option>
                  </select>
                  {workLocationDetail === "other" && (
                    <input
                      type="text"
                      placeholder="Enter your work address"
                      value={customWorkAddress}
                      onChange={(e) => setCustomWorkAddress(e.target.value)}
                      className="animate-fade-in"
                    />
                  )}
                </>
              )}
              <div className="caption">
                Office deliveries to keep you productive! 🏢💼
              </div>
            </div>
            {(deliveryOption === "home" || deliveryOption === "work") && (
              <button type="submit" className="submit-button">
                Submit Address
              </button>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default DeliveryLocation;
