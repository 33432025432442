import React, { useState } from "react";
import { TextField, InputAdornment } from "@mui/material";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { useNavigate } from "react-router-dom";
import "./Login.css";

const EmailStep = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleNext = (e) => {
    e.preventDefault();
    if (!email) {
      setError("Please enter a valid email.");
      return;
    }
    navigate("/login/password", { state: { email } });
  };

  return (
    <div className="login-page">
      <div className="login-container">
        <h2>Login</h2>
        <p className="logo-matter">
          Please use your company email to log in. The password will be sent to
          your registered email ID. For security reasons, ensure your email is
          up-to-date and monitor for updates.
        </p>
        {error && <p className="error-message">{error}</p>}
        <form onSubmit={handleNext} className="login-form">
          {/* Custom label */}
          <label htmlFor="email" className="custom-label">
            Email Address
          </label>

          <TextField
            id="email" // To link the label and input
            placeholder="Enter your email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            variant="outlined"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle style={{ color: "#888" }} />
                </InputAdornment>
              ),
            }}
            sx={{
              marginBottom: "20px", // Adjust to give space below the label
              "& .MuiOutlinedInput-root": {
                "& fieldset": { borderColor: "#ddd" },
                "&:hover fieldset": { borderColor: "#bbb" },
                "&.Mui-focused fieldset": { borderColor: "#007bff" },
                borderRadius: "8px",
                backgroundColor: "#f7f7f7",
              },
            }}
          />
          <button type="submit" className="next-button">
            Next
          </button>
        </form>
      </div>
    </div>
  );
};

export default EmailStep;
