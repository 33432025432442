import React, { useEffect, useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import "./ProductDetails.css";
import { API_BASE_URL } from "./constants";

const ProductDetails = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { cartCount: initialCartCount, balance: initialBalance } =
    location.state || { cartCount: 0, balance: 0 };
  const [cartCount, setCartCount] = useState(initialCartCount);
  const [balance, setBalance] = useState(initialBalance);
  const [product, setProduct] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedAttribute, setSelectedAttribute] = useState("");
  const [selectedAttribute2, setSelectedAttribute2] = useState(""); // State for the second set of attributes

  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await fetch(`${API_BASE_URL}/products/${id}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch product details");
        }

        const data = await response.json();
        setProduct(data);
      } catch (error) {
        console.error(error);
        setErrorMessage("Unable to fetch product details.");
      }
    };

    fetchProductDetails();
  }, [id]);

  const addToCart = async () => {
    if (!product) return; // Ensure product is available
    if (balance < 0) {
      alert("Cart is already full");
    } else {
      try {
        const token = localStorage.getItem("token");
        const response = await fetch(`${API_BASE_URL}/cart`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            product_id: product.id,
            selected_attribute: `${selectedAttribute || "N/A"},${
              selectedAttribute2 || "N/A"
            }`, // Use 'N/A' if attribute is not selected
          }),
        });

        if (!response.ok) {
          throw new Error("Failed to add product to cart");
        }

        // Update the cart count and balance locally
        setCartCount((prevCount) => prevCount + 1); // Increment cart count
        setBalance((prevBalance) => prevBalance - product.price); // Subtract product price from balance

        alert("Product added to cart!");

        // Navigate back to the Dashboard or wherever appropriate
        navigate("/dashboard", {
          state: { cartCount: cartCount + 1, balance: balance - product.price },
        });
      } catch (error) {
        setErrorMessage("Cart is already full");
        console.error("Error adding product to cart:", error);
      }
    }
  };

  if (!product) return <div>Loading...</div>;

  // Split custom attributes into arrays
  const customAttributesArray = product.custom_attributes
    ? product.custom_attributes.split(",")
    : [];
  const customAttributesArray2 = product.custom_attributes_2
    ? product.custom_attributes_2.split(",")
    : []; // Handle second custom attributes

  return (
    <div className="page-container">
      <Navbar cartItemCount={cartCount} userBalance={balance} />
      <div className="product-details-container">
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        <div className="product-details">
          <div className="product-image">
            <h2 className="pd-heading">Product Info</h2>
            <img
              className="pd-image"
              src={product.image_url}
              alt={product.name}
            />
          </div>
          <div className="product-detail-info">
            <h1>{product.name}</h1>
            <p>{product.description || "N/A"}</p>
            {/* <p className="price">Price: {product.price} points</p> */}

            {/* Display product link if available */}
            {/* {product.productlink ? (
              <p>
                <a
                  href={product.productlink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View Product Link
                </a>
              </p>
            ) : (
              <p>N/A</p>
            )} */}

            {/* Display first set of custom attributes as radio buttons */}
            {customAttributesArray.length > 0 && (
              <div className="custom-attributes">
                <h3>Select an Option:</h3>
                <select
                  value={selectedAttribute}
                  onChange={(e) => setSelectedAttribute(e.target.value)}
                >
                  <option value="">Select an option</option>
                  {customAttributesArray.map((attribute, index) => (
                    <option key={index} value={attribute}>
                      {attribute}
                    </option>
                  ))}
                </select>
              </div>
            )}

            {/* Dropdown for second set of custom attributes */}
            {customAttributesArray2.length > 0 && (
              <div className="custom-attributes">
                <h3>Select Another Option:</h3>
                <select
                  value={selectedAttribute2}
                  onChange={(e) => setSelectedAttribute2(e.target.value)}
                >
                  <option value="">Select another option</option>
                  {customAttributesArray2.map((attribute, index) => (
                    <option key={index} value={attribute}>
                      {attribute}
                    </option>
                  ))}
                </select>
              </div>
            )}

            <button className="add-to-cart-btn" onClick={addToCart}>
              Add to Cart
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
