import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Login from "./components/Login";
import Profile from "./components/Profile";
import Dashboard from "./components/Dashboard";
import DeliveryLocation from "./components/DeliveryLocation";
import { setAuthToken } from "./services/api";
import Cart from "./components/Cart";
import Orders from "./components/Orders";
import Checkout from "./components/Checkout";
import ResetPassword from "./components/ResetPassword";
import EmailStep from "./components/EmailStep";
import PasswordStep from "./components/PasswordStep";
import LocationPage from "./components/LocationStep";
import HomeLocation from "./components/HomeLocation";
import WorkLocation from "./components/WorkLocation";
import ProductDetails from "./components/ProductDetails";
import BundleDetails from "./components/BundleDetails";
import AdminEmailStep from "./components/AdminEmailStep";
import AdminCycle from "./components/AdminCycle";
import AdminDashboard from "./components/AdminDashboard";
import UsersPage from "./components/UsersPage";
import AdminProductsPage from "./components/AdminProductsPage";
import AdminOrders from "./components/AdminOrders";
import RouteGuard from "./services/RouterGuard";

const App = () => {
  const [token, setToken] = useState(localStorage.getItem("token") || "");

  const handleSetToken = (newToken) => {
    setToken(newToken);
    localStorage.setItem("token", newToken);
    setAuthToken(newToken);
  };

  return (
    <Router>
      <div className="container">
        <RouteGuard>
          <Routes>
            <Route path="/" element={<Navigate to="/login/email" />} />
            <Route
              path="/delivery-location"
              element={<DeliveryLocation token={token} />}
            />
            <Route
              path="/dashboard"
              element={
                token ? (
                  <Dashboard user={{ id: 1 }} />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route path="/cart" element={<Cart />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/orders" element={<Orders />} />
            <Route path="/login/email" element={<EmailStep />} />
            <Route
              path="/login/password"
              element={<PasswordStep setToken={handleSetToken} />}
            />
            <Route path="/location" element={<LocationPage />} />
            <Route path="/home-location" element={<HomeLocation />} />
            <Route path="/work-location" element={<WorkLocation />} />
            <Route path="/products/:id" element={<ProductDetails />} />
            <Route path="/bundles/:bundleId" element={<BundleDetails />} />
            <Route
              path="/admin/email"
              element={<AdminEmailStep setToken={handleSetToken} />}
            />
            <Route path="/admin/cycles" element={<AdminCycle />} />
            <Route
              path="/admin/cycles/dashboard/cycles/:cycleId"
              element={<AdminDashboard />}
            />
            <Route
              path="/admin/cycles/:cycleId/users"
              element={<UsersPage />}
            />
            <Route
              path="/admin/cycles/:cycleId/products"
              element={<AdminProductsPage />}
            />
            <Route
              path="/admin/cycles/:cycleId/orders"
              element={<AdminOrders />}
            />
          </Routes>
        </RouteGuard>
      </div>
    </Router>
  );
};

export default App;
