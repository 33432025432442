import React, { useEffect, useState } from "react";
import "./AdminProductsPage.css";
import AdminNavbar from "./AdminNavbar";
import { useParams } from "react-router-dom";
import { API_BASE_URL } from "./constants";
import { UploadFile } from "@mui/icons-material";

const AdminProductsPage = () => {
  const { cycleId } = useParams();
  const [products, setProducts] = useState([]);
  const [bundles, setBundles] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showBundleModal, setShowBundleModal] = useState(false);
  const [productName, setProductName] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [productPrice, setProductPrice] = useState("");
  const [productImageUrl, setProductImageUrl] = useState("");
  const [productCategory, setProductCategory] = useState("");
  const [customAttributes, setCustomAttributes] = useState("");
  const [customAttributes2, setCustomAttributes2] = useState("");
  const [productLink, setProductLink] = useState("");

  // State for bundle and product visibility
  const [bundleVisibility, setBundleVisibility] = useState(false);
  const [productVisibility, setProductVisibility] = useState(false);
  const [shippingCharges, setShippingCharges] = useState(0.0);

  const [bundleName, setBundleName] = useState("");
  const [bundleCost, setBundleCost] = useState("");
  const [bundleImageUrl, setBundleImageUrl] = useState("");
  const [selectedProducts, setSelectedProducts] = useState(new Set()); // Set to track selected product IDs
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [showUpdateModal, setShowUpdateModal] = useState(false);

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [currency, setCurrency] = useState("₹");
  const [sizeLink, setSizeLink] = useState("");

  const handleCurrencyChange = (event) => {
    setCurrency(event.target.value);
  };
  useEffect(() => {
    if (cycleId) {
      fetchProductsByCycleId(cycleId);
      fetchVisibilitySettings(cycleId);
      fetchBundlesByCycleId(cycleId);
    }
  }, [cycleId]);

  const openConfirmModal = (productId) => {
    setSelectedProductId(productId);
    setShowConfirmModal(true);
  };

  const closeConfirmModal = () => {
    setShowConfirmModal(false);
    setSelectedProductId(null);
  };

  const handleConfirmRemove = () => {
    handleRemoveProduct(selectedProductId);
    closeConfirmModal();
  };

  const toggleProductSelection = (productId) => {
    setSelectedProducts((prev) => {
      const newSelection = new Set(prev);
      if (newSelection.has(productId)) {
        newSelection.delete(productId);
      } else {
        newSelection.add(productId);
      }
      return newSelection;
    });
  };

  const handleOpenUpdateModal = (product) => {
    setSelectedProduct(product); // Populate the selected product
    setProductName(product.name);
    setProductDescription(product.description);
    setProductPrice(product.price);
    setProductImageUrl(product.image_url);
    setProductCategory(product.category);
    setCustomAttributes(product.custom_attributes || "");
    setCustomAttributes2(product.custom_attributes_2 || "");
    setSizeLink(product.size_url || "");
    setShowUpdateModal(true); // Open the update modal
  };

  const handleUpdateProduct = async (e) => {
    e.preventDefault();
    const parsedPrice = parseFloat(productPrice);

    if (
      productName &&
      productDescription &&
      !isNaN(parsedPrice) &&
      parsedPrice >= 0 &&
      productImageUrl &&
      productCategory
    ) {
      try {
        const token = localStorage.getItem("adminToken");
        const response = await fetch(
          `${API_BASE_URL}/products/${selectedProduct.id}`,
          {
            method: "PUT",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              name: productName,
              description: productDescription,
              price: parsedPrice,
              image_url: productImageUrl,
              category: productCategory,
              custom_attributes: customAttributes,
              custom_attributes_2: customAttributes2,
              size_url: sizeLink,
            }),
          }
        );
        console.log(sizeLink);

        if (response.ok) {
          fetchProductsByCycleId(cycleId); // Refresh product list
          setShowUpdateModal(false); // Close modal
          setSelectedProduct(null); // Reset selected product
        } else {
          console.error("Error updating product:", response.statusText);
        }
      } catch (error) {
        console.error("Error updating product:", error);
      }
    } else {
      console.error("Please ensure all fields are filled correctly.");
    }
  };

  const fetchProductsByCycleId = async (cycleId) => {
    try {
      const token = localStorage.getItem("adminToken");
      const response = await fetch(
        `${API_BASE_URL}/cycles/${cycleId}/products`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) throw new Error("Network response was not ok");
      const data = await response.json();
      if (data.products && Array.isArray(data.products)) {
        setProducts(data.products);
        setAvailableProducts(data.products);
      } else {
        console.error(
          "Expected products to be an array but received:",
          data.products
        );
        setProducts([]);
      }
    } catch (error) {
      console.error("Error fetching products:", error);
      setProducts([]);
    }
  };

  const fetchBundlesByCycleId = async (cycleId) => {
    try {
      const token = localStorage.getItem("adminToken");
      const response = await fetch(
        `${API_BASE_URL}/cycles/${cycleId}/bundles`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) throw new Error("Network response was not ok");
      const data = await response.json();
      if (data.bundles && Array.isArray(data.bundles)) {
        setBundles(data.bundles);
      } else {
        console.error(
          "Expected bundles to be an array but received:",
          data.bundles
        );
        setBundles([]);
      }
    } catch (error) {
      console.error("Error fetching bundles:", error);
      setBundles([]);
    }
  };

  const fetchVisibilitySettings = async (cycleId) => {
    try {
      const token = localStorage.getItem("adminToken");
      // Fetching bundle visibility
      const bundleResponse = await fetch(
        `${API_BASE_URL}/cycles/${cycleId}/bundle-visibility`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      const bundleData = await bundleResponse.json();
      if (bundleResponse.ok) {
        setBundleVisibility(bundleData.is_bundle_visible);
      }

      // Fetching products visibility
      const productsResponse = await fetch(
        `${API_BASE_URL}/cycles/${cycleId}/products-visibility`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      const productsData = await productsResponse.json();
      if (productsResponse.ok) {
        setProductVisibility(productsData.is_products_visible);
      }
    } catch (error) {
      console.error("Error fetching visibility settings:", error);
    }
  };

  const toggleBundleVisibility = async () => {
    try {
      const token = localStorage.getItem("adminToken");
      const response = await fetch(
        `${API_BASE_URL}/cycles/${cycleId}/toggle-bundle-visibility`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setBundleVisibility(data.is_bundle_visible); // Update state with new visibility
      } else {
        console.error("Error toggling bundle visibility:", response.statusText);
      }
    } catch (error) {
      console.error("Error toggling bundle visibility:", error);
    }
  };

  const handleAddBundle = async (e) => {
    e.preventDefault();

    // Ensure the required fields are populated
    if (bundleName && bundleImageUrl && selectedProducts.size > 0) {
      const selectedProductsArray = Array.from(selectedProducts);
      // Prepare the request body for the API call
      const bundleData = {
        name: bundleName,
        image_url: bundleImageUrl,
        bundle_cost: bundleCost,
        product_ids: selectedProductsArray,
      };

      try {
        const token = localStorage.getItem("token");
        const response = await fetch(
          `${API_BASE_URL}/cycles/${cycleId}/bundles`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(bundleData),
          }
        );

        if (response.ok) {
          const data = await response.json();

          // Reset form fields
          setBundleName("");
          setBundleImageUrl("");
          setSelectedProducts(new Set());
          setShowBundleModal(false); // Close modal after submission
          fetchBundlesByCycleId(cycleId);
        } else {
          const errorData = await response.json();
          console.error("Error adding bundle:", response.statusText, errorData);
        }
      } catch (error) {
        console.error("Error adding bundle:", error);
      }
    } else {
      console.error(
        "Please ensure all fields are filled correctly and at least one product is selected."
      );
    }
  };

  const toggleProductVisibility = async () => {
    try {
      const token = localStorage.getItem("adminToken");
      const response = await fetch(
        `${API_BASE_URL}/cycles/${cycleId}/toggle-products-visibility`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setProductVisibility(data.is_products_visible); // Update state with new visibility
      } else {
        console.error(
          "Error toggling product visibility:",
          response.statusText
        );
      }
    } catch (error) {
      console.error("Error toggling product visibility:", error);
    }
  };

  const handleAddProduct = async (e) => {
    e.preventDefault();
    const parsedPrice = parseFloat(productPrice);
    if (
      productName &&
      productDescription &&
      !isNaN(parsedPrice) &&
      parsedPrice >= 0 &&
      productImageUrl &&
      productCategory
      // productLink
    ) {
      try {
        const token = localStorage.getItem("adminToken");
        const response = await fetch(
          `${API_BASE_URL}/cycles/${cycleId}/products`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              name: productName,
              description: productDescription,
              price: parsedPrice,
              image_url: productImageUrl,
              category: productCategory,
              custom_attributes: customAttributes,
              custom_attributes_2: customAttributes2,
              size_url: sizeLink,
              // productLink,
            }),
          }
        );

        if (response.ok) {
          fetchProductsByCycleId(cycleId);
          setShowModal(false);
          setProductName("");
          setProductDescription("");
          setProductPrice("");
          setProductImageUrl("");
          setProductCategory("");
          setCustomAttributes("");
          setCustomAttributes2("");
          // setProductLink("");
        } else {
          console.error("Error adding product:", response.statusText);
        }
      } catch (error) {
        console.error("Error adding product:", error);
      }
    } else {
      console.error(
        "Please ensure all fields are filled correctly, and price is a valid number."
      );
    }
  };

  const handleRemoveProduct = async (productId) => {
    try {
      const token = localStorage.getItem("adminToken");
      const response = await fetch(
        `${API_BASE_URL}/cycles/${cycleId}/products/${productId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        fetchProductsByCycleId(cycleId);
      } else {
        console.error("Error removing product:", response.statusText);
      }
    } catch (error) {
      console.error("Error removing product:", error);
    }
  };

  const handleRemoveBundle = async (bundleId) => {
    try {
      // Send DELETE request to the server to remove the bundle
      const token = localStorage.getItem("adminToken");
      const response = await fetch(
        `${API_BASE_URL}/cycles/${cycleId}/bundles/${bundleId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        fetchBundlesByCycleId(cycleId);
      } else {
        console.error("Error removing bundles:", response.statusText);
      }
    } catch (error) {
      console.error("Error removing bundle:", error);
      alert("Failed to remove bundle. Please try again later.");
    }
  };

  const handleUpdateShippingCharges = async () => {
    try {
      const token = localStorage.getItem("adminToken");
      const response = await fetch(
        `${API_BASE_URL}/cycles/${cycleId}/update-shipping-charges`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ shipping_charges: shippingCharges }),
        }
      );
      if (response.ok) {
        alert("Shipping charges updated successfully.");
      } else {
        alert("Failed to update shipping charges.");
        console.error("Error updating charge:", response.statusText);
      }
    } catch (error) {
      console.error("Error updating shipping charges:", error);
      alert("Error updating shipping charges.");
    }
  };

  const [showUploadModal, setShowUploadModal] = useState(false);
  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    const formData = new FormData();
    formData.append("file", file);
    // console.log("Form Data:", [...formData]);

    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        `${API_BASE_URL}/products/upload/${cycleId}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            // "Content-Type": "application/json",
          },
          body: formData,
        }
      );

      if (response.ok) {
        const result = await response.json();
        alert(result.message); // Show success message
        setShowUploadModal(false); // Close the modal
        fetchProductsByCycleId(cycleId);
      } else {
        const error = await response.json();
        alert(`Error: ${error.error}`);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      alert("Error uploading file.");
    }
  };

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [bundleToEdit, setBundleToEdit] = useState(null);
  const [availableProducts, setAvailableProducts] = useState([]); // For listing products to add

  const openEditModal = async (bundle, cycleId) => {
    setBundleToEdit(bundle); // Set the selected bundle for editing
    setIsEditModalOpen(true); // Open the modal

    // Fetch available products for the specific cycle
    await fetchProductsByCycleId(cycleId);
  };

  const handleAddProductInBundle = (e) => {
    const productId = e.target.value;
    const productToAdd = availableProducts.find(
      (product) => product.id === parseInt(productId)
    );

    if (productToAdd) {
      setBundleToEdit((prevBundle) => ({
        ...prevBundle,
        products: [...prevBundle.products, productToAdd], // Add product to the bundle
      }));
    }
  };

  const handleRemoveProductInBundle = (index) => {
    setBundleToEdit((prevBundle) => ({
      ...prevBundle,
      products: prevBundle.products.filter((_, i) => i !== index), // Remove product by index
    }));
  };

  const handleUpdateBundle = async () => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/bundles/${bundleToEdit.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: bundleToEdit.name,
            image_url: bundleToEdit.image_url,
            products: bundleToEdit.products.map((product) => product.id), // Send product IDs
          }),
        }
      );

      if (response.ok) {
        alert("Bundle updated successfully!");
        fetchBundlesByCycleId(cycleId);
        setIsEditModalOpen(false); // Close modal after successful update
        // Optionally, fetch updated bundles to refresh UI
      } else {
        alert("Failed to update the bundle.");
      }
    } catch (error) {
      console.error("Error updating bundle:", error);
      alert("An error occurred while updating the bundle.");
    }
  };

  return (
    <div className="admin-products-page">
      <AdminNavbar cycleId={cycleId} />
      <div className="products-content">
        <h2>Products</h2>
        <div className="visibility-settings">
          <label>
            <input
              type="checkbox"
              checked={bundleVisibility}
              onChange={toggleBundleVisibility} // Toggle bundle visibility on change
            />
            Bundle Visibility
          </label>
          <label>
            <input
              type="checkbox"
              checked={productVisibility}
              onChange={toggleProductVisibility} // Toggle product visibility on change
            />
            Product Visibility
          </label>

          {/* <div className="form-group">
                        <label htmlFor="shippingCharges">Shipping Charges:</label>
                        <input type="number" id="shippingCharges" value={shippingCharges} onChange={(e) => setShippingCharges(e.target.value)} placeholder="Enter shipping charges" required />
                        <button type="button"  onClick={handleUpdateShippingCharges} >OK</button>
                    </div> */}
        </div>
        <button
          className="add-product-button"
          onClick={() => setShowModal(true)}
        >
          Add Product
        </button>
        <button
          className="add-product-button"
          onClick={() => setShowUploadModal(true)}
        >
          Upload Product
        </button>

        <button
          className="add-bundle-button"
          onClick={() => setShowBundleModal(true)}
        >
          Add Bundle
        </button>
        <div className="products-grid">
          {Array.isArray(products) && products.length > 0 ? (
            products.map((product) => (
              <div className="product-card" key={product.id}>
                <img
                  src={product.image_url}
                  alt={product.name}
                  className="product-image"
                />
                <h3 className="product-name">{product.name}</h3>
                <p className="product-description">{product.description}</p>
                <p className="product-price">
                  {currency}
                  {product.price.toFixed(2)}
                </p>
                <p className="product-category">Category: {product.category}</p>
                <p className="product-attributes">
                  Attributes:{" "}
                  {product.custom_attributes ? product.custom_attributes : "NA"}
                </p>
                <p className="product-attributes">
                  Attributes:{" "}
                  {product.custom_attributes_2
                    ? product.custom_attributes_2
                    : "NA"}
                </p>

                <button
                  className="update-product-button"
                  onClick={() => handleOpenUpdateModal(product)}
                >
                  Update
                </button>
                {/* <p className="product-link">Link: <a href={product.productlink} target="_blank" rel="noopener noreferrer">{product.productLink}</a></p> */}
                <button
                  className="remove-product-button"
                  onClick={() => openConfirmModal(product.id)}
                >
                  Remove
                </button>
              </div>
            ))
          ) : (
            <p>No products found.</p>
          )}
        </div>

        {/* Display bundles */}
        <h2>Swag Kits</h2>
        <div className="bundles-grid">
          {Array.isArray(bundles) && bundles.length > 0 ? (
            bundles.map((bundle) => (
              <div className="bundle-card" key={bundle.id}>
                <img
                  src={bundle.image_url}
                  alt={bundle.name}
                  className="bundle-image"
                />
                <h3 className="bundle-name">{bundle.name}</h3>
                <div className="bundle-products">
                  <h4>Products in this Bundle:</h4>
                  {bundle.products && bundle.products.length > 0 ? (
                    bundle.products.map((product) => (
                      <p key={product.id}>{product.name}</p>
                    ))
                  ) : (
                    <p>No products in this bundle.</p>
                  )}
                </div>
                {/* Remove Button */}
                <button
                  className="edit-bundle-button"
                  onClick={() => openEditModal(bundle, cycleId)}
                >
                  Edit
                </button>
                <button
                  className="remove-bundle-button"
                  onClick={() => handleRemoveBundle(bundle.id)}
                >
                  Remove
                </button>
              </div>
            ))
          ) : (
            <p>No bundles found.</p>
          )}
        </div>
      </div>

      {isEditModalOpen && bundleToEdit && (
        <div
          className="modal"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
          }}
        >
          <div
            className="modal-content"
            style={{
              backgroundColor: "#fff",
              padding: "20px",
              borderRadius: "10px",
              width: "500px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            }}
          >
            <h2
              style={{
                fontSize: "1.5rem",
                marginBottom: "1rem",
                color: "#333",
                textAlign: "center",
              }}
            >
              Edit Bundle: {bundleToEdit.name}
            </h2>

            {/* Edit bundle name */}
            <label
              htmlFor="bundleName"
              style={{
                fontSize: "1rem",
                marginBottom: "0.5rem",
                display: "block",
              }}
            >
              Bundle Name:
            </label>
            <input
              type="text"
              id="bundleName"
              value={bundleToEdit.name}
              onChange={(e) =>
                setBundleToEdit({ ...bundleToEdit, name: e.target.value })
              }
              style={{
                width: "100%",
                padding: "8px",
                marginBottom: "1rem",
                borderRadius: "5px",
                border: "1px solid #ccc",
                fontSize: "1rem",
              }}
            />

            {/* Edit bundle image */}
            <label
              htmlFor="bundleImageUrl"
              style={{
                fontSize: "1rem",
                marginBottom: "0.5rem",
                display: "block",
              }}
            >
              Image URL:
            </label>
            <input
              type="text"
              id="bundleImageUrl"
              value={bundleToEdit.image_url}
              onChange={(e) =>
                setBundleToEdit({ ...bundleToEdit, image_url: e.target.value })
              }
              style={{
                width: "100%",
                padding: "8px",
                marginBottom: "1rem",
                borderRadius: "5px",
                border: "1px solid #ccc",
                fontSize: "1rem",
              }}
            />

            {/* Display products in bundle */}
            <h4 style={{ marginBottom: "0.5rem", color: "#333" }}>
              Products in this Bundle:
            </h4>
            {bundleToEdit.products && bundleToEdit.products.length > 0 ? (
              bundleToEdit.products.map((product, index) => (
                <div
                  key={product.id}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "5px 0",
                  }}
                >
                  <span style={{ fontSize: "1rem", color: "#555" }}>
                    {product.name}
                  </span>
                  <button
                    onClick={() => handleRemoveProductInBundle(index)}
                    style={{
                      padding: "6px 12px",
                      backgroundColor: "#ff4d4d",
                      color: "#fff",
                      border: "none",
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}
                  >
                    Remove
                  </button>
                </div>
              ))
            ) : (
              <p style={{ fontSize: "1rem", color: "#888" }}>
                No products in this bundle.
              </p>
            )}

            {/* Add a product to the bundle */}
            <label
              htmlFor="productAdd"
              style={{
                fontSize: "1rem",
                marginBottom: "0.5rem",
                display: "block",
              }}
            >
              Add Product:
            </label>
            <select
              onChange={handleAddProductInBundle}
              value=""
              style={{
                width: "100%",
                padding: "8px",
                borderRadius: "5px",
                border: "1px solid #ccc",
                marginBottom: "1rem",
                fontSize: "1rem",
              }}
            >
              <option value="" disabled>
                Select a product to add
              </option>
              {availableProducts.map((product) => (
                <option key={product.id} value={product.id}>
                  {product.name}
                </option>
              ))}
            </select>

            {/* Save changes and close modal */}
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <button
                onClick={handleUpdateBundle}
                style={{
                  padding: "10px 20px",
                  backgroundColor: "#4CAF50",
                  color: "#fff",
                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",
                  fontSize: "1rem",
                }}
              >
                Save Changes
              </button>
              <button
                onClick={() => setIsEditModalOpen(false)}
                style={{
                  padding: "10px 20px",
                  backgroundColor: "#ccc",
                  color: "#333",
                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",
                  fontSize: "1rem",
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {showUploadModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h3>Upload Products CSV</h3>
            <input
              type="file"
              accept=".csv"
              onChange={handleFileChange}
              required
            />
            <div className="modal-actions">
              <button type="button" onClick={handleUpload}>
                Upload
              </button>
              <button type="button" onClick={() => setShowUploadModal(false)}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {showModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h3>Add Product</h3>
            <form onSubmit={handleAddProduct}>
              <div className="form-group">
                <label htmlFor="productName">Product Name:</label>
                <input
                  type="text"
                  id="productName"
                  value={productName}
                  onChange={(e) => setProductName(e.target.value)}
                  placeholder="Enter product name"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="productDescription">Description:</label>
                <input
                  type="text"
                  id="productDescription"
                  value={productDescription}
                  onChange={(e) => setProductDescription(e.target.value)}
                  placeholder="Enter product description"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="productPrice">Price:</label>
                <input
                  type="number"
                  id="productPrice"
                  value={productPrice}
                  onChange={(e) => setProductPrice(e.target.value)}
                  placeholder="Enter product price"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="productImageUrl">Image URL:</label>
                <input
                  type="text"
                  id="productImageUrl"
                  value={productImageUrl}
                  onChange={(e) => setProductImageUrl(e.target.value)}
                  placeholder="Enter product image URL"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="productCategory">Category:</label>
                <select
                  id="productCategory"
                  value={productCategory}
                  onChange={(e) => setProductCategory(e.target.value)}
                  required
                >
                  <option value="" disabled>
                    Select category
                  </option>
                  <option value="electronics">Electronics</option>
                  <option value="groceries">Groceries</option>
                  <option value="apparel">Apparel</option>
                  <option value="bags">Bags</option>
                  <option value="drinkware">Drinkware</option>
                  <option value="stationery">Stationery</option>
                  <option value="wearable">Wearable</option>
                  <option value="travel">Travel</option>
                  <option value="wellness">Wellness</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="customAttributes">Custom Options:</label>
                <input
                  type="text"
                  id="customAttributes"
                  value={customAttributes}
                  onChange={(e) => setCustomAttributes(e.target.value)}
                  placeholder="Enter custom attributes (comma separated)"
                />
              </div>
              <div className="form-group">
                <label htmlFor="customAttributes2">Custom Options 2:</label>
                <input
                  type="text"
                  id="customAttributes2"
                  value={customAttributes2}
                  onChange={(e) => setCustomAttributes2(e.target.value)}
                  placeholder="Enter custom attributes (comma separated)"
                />
              </div>
              <div className="form-group">
                <label htmlFor="sizeLink">Size Chart link:</label>
                <input
                  type="text"
                  id="sizeLink"
                  value={sizeLink}
                  onChange={(e) => setSizeLink(e.target.value)}
                  placeholder="Enter size chart link"
                />
              </div>

              {/* <div className="form-group">
                                <label htmlFor="productLink">Product Link:</label>
                                <input
                                    type="text"
                                    id="productLink"
                                    value={productLink}
                                    onChange={(e) => setProductLink(e.target.value)}
                                    placeholder="Enter product link"
                                    required
                                />
                            </div> */}
              <div className="modal-actions">
                <button type="submit">OK</button>
                <button type="button" onClick={() => setShowModal(false)}>
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {showUpdateModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h3>Update Product</h3>
            <form onSubmit={handleUpdateProduct}>
              <div className="form-group">
                <label htmlFor="productName">Product Name:</label>
                <input
                  type="text"
                  id="productName"
                  value={productName}
                  onChange={(e) => setProductName(e.target.value)}
                  placeholder="Enter product name"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="currency-select">Select Currency:</label>
                <select
                  id="currency-select"
                  value={currency}
                  onChange={handleCurrencyChange}
                >
                  <option value="₹">₹ Rupee</option>
                  <option value="$">$ Dollar</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="productDescription">Description:</label>
                <input
                  type="text"
                  id="productDescription"
                  value={productDescription}
                  onChange={(e) => setProductDescription(e.target.value)}
                  placeholder="Enter product description"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="productPrice">Price:</label>
                <input
                  type="number"
                  id="productPrice"
                  value={productPrice}
                  onChange={(e) => setProductPrice(e.target.value)}
                  placeholder="Enter product price"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="productImageUrl">Image URL:</label>
                <input
                  type="text"
                  id="productImageUrl"
                  value={productImageUrl}
                  onChange={(e) => setProductImageUrl(e.target.value)}
                  placeholder="Enter product image URL"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="productCategory">Category:</label>
                <select
                  id="productCategory"
                  value={productCategory}
                  onChange={(e) => setProductCategory(e.target.value)}
                  required
                >
                  <option value="" disabled>
                    Select category
                  </option>
                  <option value="electronics">Electronics</option>
                  <option value="groceries">Groceries</option>
                  <option value="apparel">Apparel</option>
                  <option value="bags">Bags</option>
                  <option value="drinkware">Drinkware</option>
                  <option value="stationery">Stationery</option>
                  <option value="wearable">Wearable</option>
                  <option value="travel">Travel</option>
                  <option value="wellness">Wellness</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="customAttributes">Custom Options:</label>
                <input
                  type="text"
                  id="customAttributes"
                  value={customAttributes}
                  onChange={(e) => setCustomAttributes(e.target.value)}
                  placeholder="Enter custom attributes"
                />
              </div>
              <div className="form-group">
                <label htmlFor="customAttributes2">Custom Options 2:</label>
                <input
                  type="text"
                  id="customAttributes2"
                  value={customAttributes2}
                  onChange={(e) => setCustomAttributes2(e.target.value)}
                  placeholder="Enter custom attributes 2"
                />
              </div>
              <div className="form-group">
                <label htmlFor="sizeLink">Size Chart link:</label>
                <input
                  type="text"
                  id="sizeLink"
                  value={sizeLink}
                  onChange={(e) => setSizeLink(e.target.value)}
                  placeholder="Enter size chart link"
                />
              </div>
              <div className="modal-actions">
                <button type="submit">Update</button>
                <button type="button" onClick={() => setShowUpdateModal(false)}>
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Bundle Modal */}
      {showBundleModal && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={() => setShowBundleModal(false)}>
              &times;
            </span>
            <h2>Add New Bundle</h2>
            <form onSubmit={handleAddBundle}>
              <input
                type="text"
                placeholder="Bundle Name"
                value={bundleName}
                onChange={(e) => setBundleName(e.target.value)}
                required
              />
              <input
                type="text"
                placeholder="Bundle Image Url"
                value={bundleImageUrl}
                onChange={(e) => setBundleImageUrl(e.target.value)}
                required
              />
              <input
                type="number"
                placeholder="Bundle Cost"
                value={bundleCost}
                onChange={(e) => setBundleCost(e.target.value)}
                required
              />
              <div className="product-selection">
                <h3>Select Products for the Bundle:</h3>
                <div className="scrollable-product-list">
                  {products.map((product) => (
                    <label key={product.id} className="product-checkbox">
                      <input
                        type="checkbox"
                        checked={selectedProducts.has(product.id)}
                        onChange={() => toggleProductSelection(product.id)}
                        className="custom-checkbox"
                      />
                      {product.name}
                    </label>
                  ))}
                </div>
              </div>
              <button type="submit " className="add-bundle-button">
                Add Bundle
              </button>
            </form>
          </div>
        </div>
      )}

      {showConfirmModal && (
        <div className="modal">
          <div className="modal-content">
            <h3>Confirm Order</h3>
            <p>Are you sure you want to remove this product?</p>
            <button className="confirm-yes" onClick={handleConfirmRemove}>
              Yes
            </button>
            <button className="confirm-no" onClick={closeConfirmModal}>
              No
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminProductsPage;
