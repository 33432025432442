import React, { useEffect, useState } from "react";
import "./UsersPage.css"; // Ensure you have this CSS file for styling
import AdminNavbar from "./AdminNavbar"; // Import your AdminNavbar component
import { useParams } from "react-router-dom";
import { API_BASE_URL } from "./constants";

const UsersPage = () => {
  const { cycleId } = useParams(); // Use useParams to get cycleId

  const [usersData, setUsersData] = useState({
    total_users: 0,
    ordered_users_count: 0,
    not_ordered_users_count: 0,
    ordered_users: [],
    not_ordered_users: [],
  });

  const [filter, setFilter] = useState("all"); // State to track filter selection
  const [showModal, setShowModal] = useState(false);
  const [email, setEmail] = useState("");
  const [showUploadModal, setShowUploadModal] = useState(false); // For Upload Modal
  const [file, setFile] = useState(null);

  useEffect(() => {
    if (cycleId) {
      // Ensure cycleId is available before fetching
      fetchUsersByCycleId(cycleId);
    }
  }, [cycleId]);

  const fetchUsersByCycleId = async (cycleId) => {
    try {
      const token = localStorage.getItem("token"); // Get the token from local storage
      const response = await fetch(
        `${API_BASE_URL}/cycles/${cycleId}/user_order_stats`,
        {
          // Changed endpoint to match the provided API
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) throw new Error("Network response was not ok");
      const data = await response.json();
      setUsersData(data); // Store the entire data structure
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const handleAddUser = async (e) => {
    e.preventDefault();
    if (email) {
      try {
        const token = localStorage.getItem("token");
        const response = await fetch(
          `${API_BASE_URL}/cycles/${cycleId}/users`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ email }),
          }
        );

        if (response.ok) {
          fetchUsersByCycleId(cycleId); // Refresh the user list
          setShowModal(false); // Close the modal
          setEmail(""); // Clear the email input
        } else {
          console.error("Error adding user:", response.statusText);
        }
      } catch (error) {
        console.error("Error adding user:", error);
      }
    }
  };

  const handleRemoveUser = async (userId) => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        `${API_BASE_URL}/cycles/${cycleId}/users/${userId}`,
        {
          method: "DELETE", // Use DELETE method to remove the user
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        // Refresh the user list after removal
        fetchUsersByCycleId(cycleId);
      } else {
        console.error("Error removing user:", response.statusText);
      }
    } catch (error) {
      console.error("Error removing user:", error);
    }
  };

  // Filter users based on the selected filter
  const filteredUsers = () => {
    switch (filter) {
      case "ordered":
        return usersData.ordered_users;
      case "not_ordered":
        return usersData.not_ordered_users;
      case "all":
      default:
        return [...usersData.ordered_users, ...usersData.not_ordered_users];
    }
  };

  const handleUpload = async () => {
    const formData = new FormData();
    formData.append("file", file); // Append file to formData

    try {
      const token = localStorage.getItem("token");
      const response = await fetch(`${API_BASE_URL}/users/upload/${cycleId}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (response.ok) {
        const result = await response.json();
        alert(result.message); // Show success message
        setShowUploadModal(false); // Close the upload modal
        fetchUsersByCycleId(cycleId); // Refresh users list after successful upload
      } else {
        const error = await response.json();
        alert(`Error: ${error.error}`);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      alert("Error uploading file.");
    }
  };

  return (
    <div className="users-page">
      <AdminNavbar cycleId={cycleId} />
      <div className="users-content">
        <h2>Users</h2>

        {/* Dropdown Filter */}
        <div className="filter-dropdown">
          <label htmlFor="user-filter">Filter Users: </label>
          <select
            id="user-filter"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          >
            <option value="all">All Users</option>
            <option value="ordered">Ordered Users</option>
            <option value="not_ordered">Not Ordered Users</option>
          </select>
        </div>

        <button className="add-user-button" onClick={() => setShowModal(true)}>
          Add User
        </button>

        <button
          className="add-user-button"
          onClick={() => setShowUploadModal(true)}
        >
          Upload Users
        </button>

        <div className="users-grid">
          {filteredUsers().map((user) => (
            <div className="user-card" key={user.id}>
              <p>{user.email}</p>
              <button
                className="remove-user-button"
                onClick={() => handleRemoveUser(user.id)}
              >
                -
              </button>
            </div>
          ))}
        </div>
      </div>

      {showModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h3>Add User</h3>
            <form onSubmit={handleAddUser}>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter user email"
                required
              />
              <button type="submit">OK</button>
              <button type="button" onClick={() => setShowModal(false)}>
                Cancel
              </button>
            </form>
          </div>
        </div>
      )}

{showUploadModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h3>Upload Users CSV</h3>
            <input
              type="file"
              accept=".csv"
              onChange={(e) => setFile(e.target.files[0])} // Handle file selection
            />
            <button
              type="button"
              onClick={handleUpload}
              disabled={!file} 
              style={{marginRight: "6px"}}
            >
              Upload
            </button>
            <button type="button" onClick={() => setShowUploadModal(false)}>
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default UsersPage;
