import React, { useState, useEffect } from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate } from "react-router-dom";
import "./HomeLocation.css";
import { API_BASE_URL } from "./constants";

const HomeLocation = () => {
  const [homeFirstName, setHomeFirstName] = useState("");
  const [homeLastName, setHomeLastName] = useState("");
  const [homeEmail, setHomeEmail] = useState("");
  const [homePhoneNumber, setHomePhoneNumber] = useState("");
  const [homeAltNumber, setAltPhoneNumber] = useState("");
  const [homeAddress, setHomeAddress] = useState("");
  const [homeCity, setHomeCity] = useState("");
  const [homeState, setHomeState] = useState("");
  const [homeZipCode, setHomeZipCode] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [deliveryDetails, setDeliveryDetails] = useState(
    "December 25th, 2024 - January 10th, 2025"
  );
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserDetails = async () => {
      const token = localStorage.getItem("token");
      try {
        const response = await fetch(`${API_BASE_URL}/profile`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        if (response.ok) {
          const data = await response.json();
          setHomeFirstName(data.home_first_name || "");
          setHomeLastName(data.home_last_name || "");
          setHomeEmail(data.email || "");
          setHomePhoneNumber(data.home_phone_number || "");
          setAltPhoneNumber(data.home_alternate_phone_number || "");
          setHomeAddress(data.home_address || "");
          setHomeCity(data.home_city || "");
          setHomeState(data.home_state || "");
          setHomeZipCode(data.home_zip_code || "");
          setDeliveryDetails(data.delivery_details || "");
        } else {
          const errorData = await response.json();
          setError(errorData.error);
        }
      } catch (error) {
        setError("An error occurred while fetching user details.");
      }
    };

    fetchUserDetails();
  }, []);

  const handleSubmit = async () => {
    setMessage("");
    setError("");

    if (
      !homeFirstName.trim() ||
      !homeLastName.trim() ||
      !homeEmail.trim() ||
      !homePhoneNumber.trim() ||
      !homeAddress.trim() ||
      !homeCity.trim() ||
      !homeState.trim() ||
      !homeZipCode.trim()
    ) {
      setError("Please fill in all the fields.");
      return; // Prevent form submission
    }
    if (homePhoneNumber.length < 10 || homePhoneNumber.length > 10) {
      setError("Phone number incorrect");
      return;
    }
    if (homeAltNumber && homeAltNumber.length !== 10) {
      setError("Alternate phone number incorrect");
      return;
    }
    if (homeZipCode.length < 6 || homeZipCode.length > 6) {
      setError("Pincode incorrect");
      return;
    }

    const token = localStorage.getItem("token");

    try {
      const orderResponse = await fetch(`${API_BASE_URL}/orders`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      const orderResult = await orderResponse.json();

      if (orderResponse.ok && orderResult.orders.length > 0) {
        navigate("/orders"); // Navigate to orders page if purchases exist
        return;
      }

      const response = await fetch(`${API_BASE_URL}/set_address`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          home_first_name: homeFirstName,
          home_last_name: homeLastName,
          home_email: homeEmail,
          home_phone_number: homePhoneNumber,
          home_alternate_phone_number: homeAltNumber,
          home_address: homeAddress,
          home_city: homeCity,
          home_state: homeState,
          home_zip_code: homeZipCode,
        }),
      });

      if (response.ok) {
        setMessage("Home address saved successfully!");
        navigate("/dashboard");
      } else {
        const errorData = await response.json();
        setError(errorData.error);
      }
    } catch (error) {
      setError("An error occurred while saving the home address.");
    }
  };

  const disableArrowKeys = (e) => {
    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      e.preventDefault();
    }
  };

  return (
    <div className="location-page">
      <div className="location-container">
        <div className="location-form">
          <h2>Delivery Details</h2>
          <p>
            <span style={{ color: "red" }}>Note:</span> We anticipate your order
            will arrive between{" "}
            <span style={{ background: "yellow" }}>
              December 25th’ 2024 - January 10th’ 2025.
            </span>
          </p>
          <p>
            To ensure successful delivery, please have someone available at the
            given address to receive the package.
          </p>
          <br />
          <h3 style={{ fontSize: "18px" }}>Important:</h3>
          <li style={{ textAlign: "center" }}>
            Undelivered packages will NOT be re-shipped.{" "}
          </li>
          <li style={{ textAlign: "center" }}>
            Googlers are responsible for making their own arrangements to
            collect returned packages.
          </li>
          <br />
          <p>
            If you are unavailable,, select delayed delivery option i.e to
            receive parcel between January 15th’ 2025 - January 30th’ 2025{" "}
          </p>
          <br />
          <label>
            First Name<span style={{ color: "red" }}>*</span>
          </label>
          <input
            type="text"
            placeholder="Enter your first name"
            value={homeFirstName}
            required
            onChange={(e) => setHomeFirstName(e.target.value)}
          />
          <label>
            Last Name<span style={{ color: "red" }}>*</span>
          </label>
          <input
            type="text"
            placeholder="Enter your last name"
            value={homeLastName}
            required
            onChange={(e) => setHomeLastName(e.target.value)}
          />
          <label>
            Email<span style={{ color: "red" }}>*</span>
          </label>
          <input
            type="email"
            placeholder="Enter your email"
            value={homeEmail}
            readOnly
            onChange={(e) => setHomeEmail(e.target.value)}
          />
          <label>
            Phone Number<span style={{ color: "red" }}>*</span>
          </label>
          <input
            type="number"
            onKeyDown={disableArrowKeys}
            placeholder="Enter your phone number"
            value={homePhoneNumber}
            required
            onChange={(e) => setHomePhoneNumber(e.target.value)}
          />
          <label>Alt Phone Number</label>
          <input
            type="number"
            onKeyDown={disableArrowKeys}
            placeholder="Enter your alternate phone number"
            value={homeAltNumber}
            onChange={(e) => setAltPhoneNumber(e.target.value)}
          />
          <label>
            Home Address<span style={{ color: "red" }}>*</span>
          </label>
          <input
            type="text"
            placeholder="Enter your home address"
            value={homeAddress}
            onChange={(e) => setHomeAddress(e.target.value)}
          />
          <label>
            City<span style={{ color: "red" }}>*</span>
          </label>
          <input
            type="text"
            placeholder="Enter your city"
            value={homeCity}
            required
            onChange={(e) => setHomeCity(e.target.value)}
          />
          <label>
            State<span style={{ color: "red" }}>*</span>
          </label>
          <input
            type="text"
            placeholder="Enter your state"
            value={homeState}
            required
            onChange={(e) => setHomeState(e.target.value)}
          />
          <label>
            Zip Code<span style={{ color: "red" }}>*</span>
          </label>
          <input
            type="number"
            onKeyDown={disableArrowKeys}
            placeholder="Enter your zip code"
            value={homeZipCode}
            required
            onChange={(e) => setHomeZipCode(e.target.value)}
          />
          <label>
            Delivery Date<span style={{ color: "red" }}>*</span>
          </label>
          <select
            value={deliveryDetails}
            onChange={(e) => setDeliveryDetails(e.target.value)}
            required
          >
            <option value="December 25th, 2024 - January 10th, 2025">
              December 25th, 2024 - January 10th, 2025
            </option>
            <option value="January 15th’ 2025 - January 30th’ 2025">
              January 15th’ 2025 - January 30th’ 2025
            </option>
          </select>

          {error && <p className="error-message">{error}</p>}
          {message && <p className="success-message">{message}</p>}

          <div style={{ display: "flex", justifyContent: "center" }}>
            <button onClick={handleSubmit} className="submit-button">
              Submit <ArrowForwardIcon />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeLocation;
