import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const RouteGuard = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const restrictedRoutes = ["/cart", "/checkout"];
  const hasPlacedOrder = localStorage.getItem("hasPlacedOrder") === "true";

  useEffect(() => {
    if (hasPlacedOrder && restrictedRoutes.includes(location.pathname)) {
      navigate("/orders", { replace: true });
    }
  }, [hasPlacedOrder, location, navigate]);

  return children;
};

export default RouteGuard;
