import React from "react";
import { useParams } from "react-router-dom"; // Import useParams
import AdminNavbar from "./AdminNavbar";

const AdminDashboard = () => {
  const { cycleId } = useParams(); // Get cycleId from URL parameters

  return (
    <div>
      <AdminNavbar cycleId={cycleId} /> {/* Pass cycleId to AdminNavbar */}
      <h1>Welcome to the Admin Dashboard</h1>
      {/* Admin Dashboard content goes here */}
    </div>
  );
};

export default AdminDashboard;
