// import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
// import Navbar from "./Navbar";
// import "./Orders.css";
// import { API_BASE_URL } from "./constants";

// const Orders = () => {
//   const [orders, setOrders] = useState([]);

//   useEffect(() => {
//     const fetchOrders = async () => {
//       const token = localStorage.getItem("token");
//       const response = await fetch(`${API_BASE_URL}/orders`, {
//         method: "GET",
//         headers: {
//           Authorization: `Bearer ${token}`,
//           "Content-Type": "application/json",
//         },
//       });
//       const result = await response.json();
//       if (response.ok) {
//         setOrders(result.orders);
//       } else {
//         console.error("Failed to fetch orders:", result.error);
//       }
//     };

//     fetchOrders();
//   }, []);

//   return (
//     <div className="orders-page">
//       <Navbar cartItemCount={0} userBalance={0} />
//       <h1 className="orders-head">Your Orders</h1>
//       <p className="order-info">Thankyou for your order</p>
//       {orders.length === 0 ? (
//         <p className="orders-empty">
//           You have no orders. <Link to="/dashboard">Start shopping now!</Link>
//         </p>
//       ) : (
//         <div className="orders-items-container">
//           {orders.map((order) => {
//             const totalItems = order.items.reduce(
//               (acc, item) => acc + item.quantity,
//               0
//             );
//             return (
//               <div key={order.order_id} className="order-item">
//                 <h3 className="order-id">
//                   Order ID: {order.order_id}
//                   <span className="item-count">{totalItems}</span>
//                 </h3>
//                 <div className="orders-header">
//                   <h4>Image</h4>
//                   <h4>Item Name</h4>
//                   <h4>Quantity</h4>
//                   {/* <h4>Price</h4>
//                   <h4>Total</h4> */}
//                 </div>
//                 {order.items.map((item, index) => (
//                   <div key={index} className="order-item-details">
//                     <img
//                       src={item.image_url}
//                       alt={item.product_name}
//                       className="order-item-image"
//                     />
//                     <div className="item-info">
//                       <h4>{item.product_name}</h4>
//                       {/* <p>{item.price} points</p> */}
//                       {/* <p className="total-points">{item.total_price} points</p> */}
//                       <span>{item.quantity}</span>
//                     </div>
//                   </div>
//                 ))}
//                 {/* <div className="order-subtotal">
//                   <p className="shipping">
//                     Order Total: <strong>{order.subtotal} points</strong>
//                   </p>
//                   <p className="shipping shipping-charges">
//                     Shipping Charges: <strong>499 points</strong>
//                   </p>
//                   <p className="subtotal">
//                     Subtotal: <strong>{order.subtotal + 499} points</strong>
//                   </p>
//                 </div> */}
//               </div>
//             );
//           })}
//         </div>
//       )}
//     </div>
//   );
// };

// export default Orders;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "./Navbar";
import "./Orders.css";
import { API_BASE_URL } from "./constants";
import CircularProgress from "@mui/material/CircularProgress";

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchOrders = async () => {
      setLoading(true);

      const token = localStorage.getItem("token");
      const response = await fetch(`${API_BASE_URL}/orders`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      const result = await response.json();
      if (response.ok) {
        setOrders(result.orders);
        setLoading(false);
      } else {
        console.error("Failed to fetch orders:", result.error);
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);

  return (
    <div className="orders-page">
      <Navbar cartItemCount={0} userBalance={0} />
      <br />
      {loading ? (
        <div className="loading-spinner">
          <CircularProgress />
        </div>
      ) : orders.length === 0 ? (
        <p className="orders-empty">
          You have no orders. <Link to="/dashboard">Start shopping now!</Link>
        </p>
      ) : (
        <div className="orders-items-container">
          <h1 className="orders-head">Thank you for your order</h1>
          <p className="orders-info-details">
            You will receive your order updates on your registered email.
          </p>
          {orders.map((order) => {
            const totalItems = order.items.reduce(
              (acc, item) => acc + item.quantity,
              0
            );
            return (
              <div key={order.order_id} className="order-item">
                <h3 className="order-id">
                  Order ID: {order.order_id}
                  <span className="item-count">{totalItems}</span>
                </h3>
                {order.items.map((item, index) => (
                  <div key={index} className="order-item-details">
                    <img
                      src={item.image_url}
                      alt={item.product_name}
                      className="order-item-image"
                    />
                    <div className="item-info">
                      <h4>{item.product_name}</h4>
                      <p>
                        Quantity - {item.quantity}, ({order.status})
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Orders;
