import React from "react";
import { Link } from "react-router-dom";
import "./AdminNavbar.css";
import CloseIcon from "@mui/icons-material/Close";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PeopleIcon from "@mui/icons-material/People";
import ListAltIcon from "@mui/icons-material/ListAlt";
import SettingsIcon from "@mui/icons-material/Settings";

const AdminNavbar = ({ cycleId }) => {
  // Accept cycleId as a prop

  const handleLogout = () => {
    localStorage.removeItem("adminToken");
    window.location.href = "/admin/email"; // Redirect to admin login page
  };

  return (
    <>
      <nav className="admin-navbar">
        <h1 className="admin-navbar-title">Admin Dashboard</h1>
        <button className="close-admin-sidenav-button" onClick={handleLogout}>
          <CloseIcon className="admin-navbar-icon" />
        </button>
      </nav>

      <div className="admin-sidenav admin-sidenav-visible">
        {" "}
        {/* Always visible */}
        <Link
          to={`/admin/cycles/dashboard/cycles/${cycleId}`}
          className="admin-sidenav-link"
        >
          <DashboardIcon className="admin-sidenav-icon" /> Dashboard
        </Link>
        <Link
          to={`/admin/cycles/${cycleId}/orders`}
          className="admin-sidenav-link"
        >
          <ListAltIcon className="admin-sidenav-icon" /> Orders
        </Link>
        <Link
          to={`/admin/cycles/${cycleId}/products`}
          className="admin-sidenav-link"
        >
          <ShoppingCartIcon className="admin-sidenav-icon" /> Products
        </Link>
        <Link
          to={`/admin/cycles/${cycleId}/users`}
          className="admin-sidenav-link"
        >
          <PeopleIcon className="admin-sidenav-icon" /> Users
        </Link>
        <button className="admin-sidenav-logout" onClick={handleLogout}>
          Logout
        </button>
      </div>
    </>
  );
};

export default AdminNavbar;
