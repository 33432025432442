import React, { useState } from "react";
import { TextField, InputAdornment } from "@mui/material";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate } from "react-router-dom";
import { API_BASE_URL } from "./constants";
import "./AdminLogin.css";

const AdminLogin = ({ setToken }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  // Handle login submission for both email and password
  const handleLogin = async (e) => {
    e.preventDefault();
    setError("");

    // Basic validation check
    if (!email || !password) {
      setError("Please enter both email and password.");
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}/admin/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });

      const result = await response.json();

      if (response.ok) {
        // Save the token and redirect to admin dashboard
        setToken(result.token);
        navigate("/admin/cycles");
      } else {
        setError(result.error || "Login failed. Please try again.");
      }
    } catch (error) {
      setError("An error occurred during login.");
    }
  };

  // Common input field styling for consistency
  const inputFieldStyles = {
    "& .MuiOutlinedInput-root": {
      "& fieldset": { borderColor: "#ddd" },
      "&:hover fieldset": { borderColor: "#bbb" },
      "&.Mui-focused fieldset": { borderColor: "#007bff" },
      borderRadius: "8px",
      backgroundColor: "#f7f7f7",
    },
    "& .MuiInputLabel-root": { color: "#999" },
    "& .MuiInputLabel-root.Mui-focused": { color: "#007bff" },
  };

  return (
    <div className="login-page">
      <div className="login-container">
        <h2>Admin Login</h2>
        {error && <p className="error-message">{error}</p>}
        <form onSubmit={handleLogin} className="login-form">
          {/* Email Input */}
          <TextField
            // label="Admin Email Address"
            placeholder="Enter your email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            variant="outlined"
            fullWidth
            margin="normal"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle style={{ color: "#888" }} />
                </InputAdornment>
              ),
            }}
            sx={inputFieldStyles} // Apply consistent styles
          />

          {/* Password Input */}
          <TextField
            label="Password"
            placeholder="Enter your password"
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            variant="outlined"
            fullWidth
            margin="normal"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <span
                    onClick={() => setShowPassword(!showPassword)}
                    style={{ cursor: "pointer" }}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </span>
                </InputAdornment>
              ),
            }}
            sx={inputFieldStyles} // Apply consistent styles
          />

          {/* Submit Button */}
          <button type="submit" className="password-submit-button">
            Login <ArrowForwardIcon />
          </button>
        </form>
      </div>
    </div>
  );
};

export default AdminLogin;
