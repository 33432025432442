import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
} from "@mui/material";
import "./Cart.css";
import { API_BASE_URL } from "./constants";

const Cart = () => {
  const [cart, setCart] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [loading, setLoading] = useState(true); // Add loading state
  const navigate = useNavigate();
  const location = useLocation();
  const [balance, setBalance] = useState(location.state?.balance || 0);

  useEffect(() => {
    const fetchCart = async () => {
      const token = localStorage.getItem("token");
      setLoading(true); // Set loading to true when data fetching starts

      try {
        const cartResponse = await fetch(`${API_BASE_URL}/cart`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
        const cartResult = await cartResponse.json();

        if (cartResponse.ok) {
          const initializedCart = cartResult.map((item) => ({
            ...item,
            quantity: item.quantity || 1,
          }));
          setCart(initializedCart);
        } else {
          console.error("Failed to fetch cart:", cartResult.error);
        }
      } catch (error) {
        console.error("Fetch cart error:", error);
      } finally {
        setLoading(false); // Stop loading after data fetch is complete
      }
    };

    fetchCart();
  }, []);

  const removeFromCart = async (index) => {
    const removedItem = cart[index];
    const token = localStorage.getItem("token");
    await fetch(`${API_BASE_URL}/cart/${removedItem.cart_id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const updatedCart = cart.filter((_, i) => i !== index);
    setCart(updatedCart);
  };

  const handleQuantityChange = (index, operation) => {
    const updatedCart = [...cart];
    const item = updatedCart[index];

    if (operation === "increment") {
      // console.log(balance, item.price);
      if (balance - item.price < 0) {
        setModalMessage(
          "Insufficient balance to increase the quantity further."
        );
        setOpenModal(true);
        return;
      }
      item.quantity += 1;
      setBalance(balance - item.price);
    } else if (operation === "decrement" && item.quantity > 1) {
      item.quantity -= 1;
      setBalance(balance + item.price);
    }

    setCart(updatedCart);
  };

  const handleProceedToCheckout = () => {
    navigate("/checkout", { state: { balance, cart } });
  };

  const handleProductInfoClick = (product) => {
    setSelectedProduct(product);
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  const calculateSubtotal = () => {
    return cart.reduce((acc, item) => acc + item.price * item.quantity, 0);
  };

  const handleBackClick = () => {
    navigate("/dashboard");
  };

  const shippingCharge = 499; // Define shipping charge
  const totalWithShipping = calculateSubtotal() + shippingCharge; // Calculate total with shipping

  return (
    <div className="cart-page">
      <Navbar cartItemCount={cart.length} userBalance={balance} />
      <div className="back-button-container">
        <button onClick={handleBackClick} className="back-button">
          &larr;&nbsp; Back
        </button>
      </div>
      <h1 className="orders-head">Your Cart</h1>
      {/* Show loading spinner while data is being fetched */}
      {loading ? (
        <div className="loading-spinner">
          <CircularProgress />
        </div>
      ) : (
        <>
          {cart.length === 0 ? (
            <p className="cart-empty">
              Your cart is empty.{" "}
              <Link to="/dashboard">Go back to shopping</Link>
            </p>
          ) : (
            <div className="cart-items-container">
              {cart.map((item, index) => (
                <div key={index} className="cart-item">
                  <img
                    src={item.image_url}
                    alt={item.name}
                    className="cart-item-image"
                  />
                  <div className="cart-item-details">
                    <div className="cart-item-name">
                      <h3>{item.name}</h3>
                      <p>
                        <br />
                        {!item.name.toLowerCase().includes("swag kit")
                          ? item.description
                          : item.description
                              .replace("Bundle containing:", "")
                              .split(",")
                              .map((desc, index) => {
                                const productName = desc.trim();
                                const attribute =
                                  item.selected_attribute[productName];
                                return (
                                  <span key={index}>
                                    {`${index + 1}. ${productName}`}{" "}
                                    {attribute ? `(${attribute})` : ""}
                                    <br />
                                  </span>
                                );
                              })}
                      </p>

                      {item.selected_attribute && (
                        <p className="selected-attribute">
                          {item.selected_attribute}
                        </p>
                      )}
                    </div>
                    {item.bundle_id ? (
                      <div className="bundle-details">
                        {/* <p>Bundle Total: {item.total_price} points</p> */}
                        {/* <p>Contents: {item.description}</p> */}
                      </div>
                    ) : (
                      <div className="quantity-control">
                        <button
                          onClick={() =>
                            handleQuantityChange(index, "decrement")
                          }
                        >
                          -
                        </button>
                        <span>{item.quantity}</span>
                        <button
                          onClick={() =>
                            handleQuantityChange(index, "increment")
                          }
                        >
                          +
                        </button>
                      </div>
                    )}
                    <div className="cart-item-price-points">
                      {/* <p className="total-price">
                        Total:{" "}
                        {item.bundle_id ? item.price : item.price * item.quantity}{" "}
                        points
                      </p> */}
                      <button
                        className="remove-button"
                        onClick={() => removeFromCart(index)}
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                </div>
              ))}
              {/* <div className="subtotal-container">
                <h3>Subtotal: {calculateSubtotal()} points</h3>
                <h3>Shipping Charges: {shippingCharge} points</h3>
                <h3>Total (including shipping): {totalWithShipping} points</h3>
              </div> */}
              <button
                className="checkout-button"
                onClick={handleProceedToCheckout}
              >
                Proceed to Checkout
              </button>
            </div>
          )}
        </>
      )}

      <Dialog open={openModal} onClose={closeModal}>
        <DialogTitle>Insufficient Balance</DialogTitle>
        <DialogContent>
          <p>{modalMessage}</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      {selectedProduct && (
        <div className="modal">
          <div className="modal-content">
            <span className="close-button" onClick={closeModal}>
              ✖️
            </span>
            <h2>{selectedProduct.name}</h2>
            <img src={selectedProduct.image_url} alt={selectedProduct.name} />
            <p>{selectedProduct.description}</p>
            <p className="price">Price: {selectedProduct.price} points</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Cart;
